import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import './SuccessToast.css';

const SuccessToast = ({ isOpen, message, onToastClose, onClose }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    let intervalId;
    if (isOpen) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => prevProgress - 4);
      }, 100);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isOpen]);

  useEffect(() => {
    if (progress === 0) {
      onToastClose();
      onClose();
    }
  }, [progress, onToastClose, onClose]);

  const handleClose = () => {
    onToastClose();
      onClose();
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div className="successToast-modal">
      <div className="successToast-modal-content">
        <div className="cell">
          <div className="toast">
            <div className="icon">
              <FaCheck />
            </div>
            <div className="content">
              <p className="type">Success</p>
              <p className="message">{message}</p>
            </div>
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            <div className="close" onClick={handleClose}>
              <MdClose />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessToast;
