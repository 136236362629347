// YourComponent.jsx

import React from 'react';
import '../../FormBuilder.css';

const InputWithMediaElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'inputWithMediaElement' && (
        <label className='titleTextLbl'>
          <div className='inputWithMediaElementCont'>
            <div className='inputWithMediaElement'>
              {component.fieldName}
              <span className='requiredContainer' style={{ color: 'red' }}>
                {isRequired(component) }
              </span>
              <input className='inputComponent' type="text" placeholder={component.fieldName} readOnly/>
            </div>
          <div className='InputWithMediaElementBtnCon'>
            <div>
              <span className='InputWithMediaElementBtn'>Photo</span>
            </div>
            <div>
              <span className='InputWithMediaElementBtn'>Video/Audio</span>
            </div>
          </div>
          </div>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default InputWithMediaElement;
