import { atom } from 'recoil';

export const AtomRolesState = atom({
  key: 'AtomRolesState',
  default: {
    role: {
      "0": {
        "iconView": "1",
        "iconEdit": "1",
        "iconDelete": "1",
        "btnAdd": "1",
        "userAddBtn": "1",
        "deviceAddBtn": "1",
        "btnSuspend" : "1",
        "btnSite" : "1",
        "settingsMenu" : "1",
        "menuUsers" : "1",
        "menuSites" : "1",
        "menuCompany" : "1",
        "menuDevices" : "1",
        "menuRoles" : "1",
        "menuFormBuilder": "1",
        "viewUsers": "1",
      },
      "1": {
        "iconView": "1",
        "iconEdit": "1",
        "iconDelete": "1",
        "btnAdd": "1",
        "userAddBtn": "1",
        "deviceAddBtn": "1",
        "btnSuspend" : "0",
        "btnSite" : "1",
        "settingsMenu" : "1",
        "menuUsers" : "1",
        "menuSites" : "1",
        "menuCompany" : "1",
        "menuDevices" : "1",
        "menuRoles" : "1",
        "menuFormBuilder": "1",
        "viewUsers": "1"
      },
      "2": {
        "iconView": "1",
        "iconEdit": "1",
        "iconDelete": "1",
        "btnAdd": "1",
        "userAddBtn": "1",
        "deviceAddBtn": "0",
        "btnSuspend" : "0",
        "btnSite" : "1",
        "settingsMenu" : "1",
        "menuUsers" : "1",
        "menuSites" : "1",
        "menuCompany" : "0",
        "menuDevices" : "1",
        "menuRoles" : "0",
        "menuFormBuilder": "1",
        "viewUsers": "1"
      },
      "3": {
        "iconView": "1",
        "iconEdit": "1",
        "iconDelete": "0",
        "btnAdd": "1",
        "userAddBtn": "0",
        "deviceAddBtn": "0",
        "btnSuspend" : "0",
        "btnSite" : "1",
        "settingsMenu" : "1",
        "menuUsers" : "1",
        "menuSites" : "1",
        "menuCompany" : "0",
        "menuDevices" : "0",
        "menuRoles" : "0",
        "menuFormBuilder": "1",
        "viewUsers": "1"
      },
      "4": {
        "iconView": "1",
        "iconEdit": "0",
        "iconDelete": "0",
        "btnAdd": "0",
        "userAddBtn": "0",
        "deviceAddBtn": "0",
        "btnSuspend" : "0",
        "btnSite" : "0",
        "settingsMenu" : "1",
        "menuUsers" : "1",
        "menuSites" : "1",
        "menuCompany" : "0",
        "menuDevices" : "0",
        "menuRoles" : "0",
        "menuFormBuilder": "1",
        "viewUsers": "1"
      },
      "5": {
        "iconView": "1",
        "iconEdit": "0",
        "iconDelete": "0",
        "btnAdd": "0",
        "userAddBtn": "0",
        "deviceAddBtn": "0",
        "btnSuspend" : "0",
        "btnSite" : "0",
        "settingsMenu" : "0",
        "menuUsers" : "0",
        "menuSites" : "0",
        "menuCompany" : "0",
        "menuDevices" : "0",
        "menuRoles" : "0",
        "menuFormBuilder": "0",
        "viewUsers": "0"
      },
    }
  }
});

