import React from 'react';
import '../../FormBuilder.css';

const SADriversLicense2DElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'saDriversLicense2D' && (
        <label className='titleTextLbl'>
            {component.fieldName || "Scan SA Drivers License"}
            <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
      </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default SADriversLicense2DElement;
