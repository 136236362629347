import React, { useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { FaTimes } from 'react-icons/fa';
import SuccessToast from '../../../SuccessToast/SuccessToast';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import './SendEmailModal.css';

const SendEmailModal = ({ isOpen, onClose, reportDetails }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  //const [message, setMessage] = useState("");
  const [email, setEmail] = useState('');

  const filteredReportId = reportDetails.map(({ reportId }) => reportId).join(', ');
  const filteredReportName = reportDetails.map(({ reportName }) => reportName).join(', ');
  //const filteredReportData = reportDetails.map(({ reportData }) => reportData);

  const data = reportDetails[0].reportData;

  const filteredData = data.filter(item => item.elementId !== 'et');

  console.log('data*-*-*-*-*-*-', data)
   
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const sendData={
        email: email,
        reportId: filteredReportId,
        reportName: filteredReportName,
        reportData: {
          data : filteredData,
      }
    }

     console.log(sendData)
             
     axios.post(`https://myworxit.co.za/api/${companyWebValue}/sendReportViaEmail/sendReportViaEmail.php`,sendData)
     .then((res)=> {
       if (res.data.success) {
         //setMessage(res.data.message);
         setShowSuccessToast(true);
         //onClose();  // Close the modal only if the response is true
       } else {
        //setMessage(res.data.message);
       }
     })
     .catch((res) => {
       console.error(res);
       //setMessage(res.data.message);
      
     });
    console.log('Sending data to:', email);

    setEmail('');
    // Close the modal after sending
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="sendEmail-modal">
      <div className="sendEmail-modal-content">
      <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='SitesZones-title'>Email</h3>
          </div> 
              <div className='defaultBtnCont'> 
                <span className='defaultBtn' onClick={handleSubmit}>Send</span>
              </div>
          <div onClick={onClose} className='closeContAllModals' >
              <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="inputEmailAddressCont">
          <input 
            className="inputEmailAddress" 
            type="text" 
            placeholder='Enter Email Address'
            value={email} // Bind the email state to the input value
            onChange={handleChange} // Call handleChange when input value changes
          />
        </div>
      </div>
      <SuccessToast 
       // message={message} 
        isOpen={showSuccessToast} 
        onToastClose={() => setShowSuccessToast(false)} 
        onClose={() => onClose()} 
      />
    </div>
  );
};

export default SendEmailModal;
