import React, { useState } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import SuccessToast from '../../../SuccessToast/SuccessToast';
import './CreateCompanyModal.css';

const CreateCompanyModal = ({ isOpen, onClose }) => {
    const [message, setMessage] = useState("");
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [formData, setFormData] = useState({
        coNo: '',
        coName: '',
        coReg: '',
        coContact: '+27',
        coAdd: '',
        companyWeb: ''
        // Add other fields as needed
    });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //     ...formData,
    //     [name]: value,
    //     });
    // };
    const handleChange = (e) => {
      const { name, value } = e.target;

      // Check if the field is coContact and does not start with +27
      const updatedValue =
          name === 'coContact' && !value.startsWith('+27') ? `+27${value}` : value;

      setFormData({
          ...formData,
          [name]: updatedValue,
      });
  };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        const sendData = {
            coNo: formData.coNo,
            coName: formData.coName,
            coReg: formData.coReg,
            coContact: formData.coContact,
            coAdd: formData.coAdd,
            companyWeb: formData.companyWeb
          
        };
      
        console.log(sendData);
      
        axios.post(`https://myworxit.co.za/api/createCompany.php`, sendData)
          .then((res) => {
            if (res.data.success) {
              setShowSuccessToast(true);
            } else {
              setMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message); // Assuming the error message is meaningful
          });
      };
      

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-company-modal">
      <div className="create-company-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
          <h3 className="create-company-title">Create Company</h3>
          </div>
          {/* {roleValue[0]?.role === 0 || roleValue[0]?.role === 1 ? ( */}
             <div className='defaultBtnCont'>
              <span onClick={handleSubmit} className='defaultBtn' type="submit">Create</span>
             </div>
            {/* ) : null
          } */}
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
          {/* <form onSubmit={handleSubmit}> */}
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company No:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coNo"
                value={formData.coNo}
                onChange={handleChange}
              />
              </div>   
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Web:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="companyWeb"
                value={formData.companyWeb}
                onChange={handleChange}
              />
              </div>   
                       
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Name:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coName"
                value={formData.coName}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
             Company Reg:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coReg"
                value={formData.coReg}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Contact:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coContact"
                value={formData.coContact}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Address:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coAdd"
                value={formData.coAdd}
                onChange={handleChange}
              />
              </div>            
            </div>
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
             <SuccessToast 
                  message={message} 
                  isOpen={showSuccessToast} 
                  onToastClose={() => setShowSuccessToast(false)} 
                  onClose={() => onClose()} 
                />
      </div>
    </div>
  );
};

export default CreateCompanyModal;
