import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import {  useRecoilValue } from 'recoil';
import { AtomReportDataState } from '../../../../RecoilState/AtomReportDataState';
import './ViewSelectedFieldNamesModal.css';

const ViewSelectedFieldNamesModal = ({ isOpen, onClose, selectedFormId, selectedFields, setSelectedFields }) => {
    const [message, setMessage] = useState("");
    const viewFormData = useRecoilValue(AtomReportDataState);
    

    const filteredData = viewFormData.filter((data) => selectedFormId === data.formId);
    const filteredSelectedData = filteredData.filter((data) => data.elementId !== 'eemail' && data.elementId !== 'et');
    console.log('filteredSelectedData: ', filteredSelectedData)

  const handleCheckboxChange = (fieldName) => {
    setSelectedFields(prevSelectedFields => {
        if (prevSelectedFields.includes(fieldName)) {
            return prevSelectedFields.filter(item => item !== fieldName);
        } else {
            return [...prevSelectedFields, fieldName];
        }
    });
};
  
    console.log('selectedFields: ', selectedFields)

    const handleAddColumn = () => {
      // Add the selected field names to the reportsColumns state
      //setReportsColumns(prevColumns => [...prevColumns, ...selectedFields]);
      onClose()
  };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="viewSelectedFieldNames-modal">
            <div className="viewSelectedFieldNames-modal-content">
                <div className='modalHeader'>
                    <div className='header-title-cont'>
                        <h3 className="viewSelectedFieldNames-title">Available Fields</h3>
                    </div>
                    <div className='viewSelectedFieldNamesBtnCont'>
                      <span className='viewSelectedFieldNamesBtn' onClick={handleAddColumn} type="submit">Add Column</span>
                    </div>
                    <div onClick={onClose} className="viewSelectedFieldNamescloseContModals">
                        <span className="viewSelectedFieldNamescloseModalIcon">
                            <FaTimes />
                        </span>
                    </div>
                </div>
                    {filteredSelectedData.map((item, index) => (
                        <div className='availableDataCont' key={index}>
                            <div className='fieldNameData'>
                                <label htmlFor={`checkbox-${index}`}>{item.fieldName}</label>
                            </div>
                            <div className='checkboxData'>
                                <input
                                    type="checkbox"
                                    id={`checkbox-${index}`}
                                    checked={selectedFields.includes(item.fieldName)}
                                    onChange={() => handleCheckboxChange(item.fieldName)}
                                /> 
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ViewSelectedFieldNamesModal;
