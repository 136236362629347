import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import axios from 'axios';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import './EditDeviceModal.css'

const EditDeviceModal = ({ isOpen, onClose, device }) => {
  const [editableField, setEditableField] = useState(null);
  const [editedDevice, setEditDevice] = useState({ ...device });
  const headerData = useRecoilValue(AtomHeaderDataState)
  const [isLoadingSites, setIsLoadingSites] = useState(true); // Add loading state for sites
  const [message, setMessage] = useState("");
  const [viewSites, setViewSites] = useState([]);
  const [viewCoNo, setViewCoNo] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedCoNo, setSelectedCoNo] = useState('');
  const selectedCoNoObject = viewCoNo.find(item => item.coNo === selectedCoNo);
  const selectedCompanyWebValue = selectedCoNoObject?.companyWeb
  console.log('selectedCompanyWebValue: ',selectedCompanyWebValue)

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await axios.get(`https://myworxit.co.za/api/${selectedCompanyWebValue}/view/viewSites.php`);
        const newData = response.data.records;
        setViewSites(newData);
        setIsLoadingSites(false); // Set loading to false when data is fetched
        console.log('New Data cc000:', newData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
  
    fetchSites(); // Initial call
    const intervalId = setInterval(fetchSites, 5000);
    return () => clearInterval(intervalId);
  }, [setViewSites, selectedCompanyWebValue]); // Corrected dependency array
  
  useEffect(() => {
    const fetchCompanyWeb = async () => {
      try {
        const response = await axios.get(`https://myworxit.co.za/api/fetchCompanyWeb.php`);
        const newData = response.data.records;
        setViewCoNo(newData);
        console.log('newData: ', newData);
    } catch (error) {
        console.error("Error fetching data: ", error);
    }
  };
  
    fetchCompanyWeb(); // Initial call
    const intervalId = setInterval(fetchCompanyWeb, 5000);
    return () => clearInterval(intervalId);
  }, [setViewCoNo]); // Empty dependency array to run this effect only once



  const handleEditClick = (fieldName) => {
    setEditableField(fieldName);
  };

  console.log('device: ', device)

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
  
    const sendData={
      id: device.id,
      deviceId: editedDevice.deviceId,
      deviceName: editedDevice.deviceName,
      coNo: selectedCoNo || editedDevice.coNo,
      site: selectedSite || editedDevice.site,
      companyWeb: editedDevice.companyWeb, 
     }
     console.log(sendData)
     
     axios.post(`https://myworxit.co.za/api/updateDevice.php`,sendData)
     .then((res)=> {
       if (res.data.success) {
         onClose();  // Close the modal only if the response is true
       } else {
         setMessage(res.data.message);
       }
       // setMessageSuc(res.data.messageSuc);
       // setMessageError("");
       // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
     })
     .catch((res) => {
      console.error("Error updating device:", res);
      setMessage("An error occurred during the update");
      
     });
  }  

  // const handleUpdateClick = () => {
  //   // Update editedDevice state
  //   setEditDevice(prevDevice => ({
  //     ...prevDevice,
  //     // site: selectedSite,
  //     coNo: selectedCoNo
  //   }));
 
  //   // After updating, switch back to view mode
  //   setEditableField(null);
  // };
  
  const handleUpdateClick = () => {
    // Find the selected coNo object from the viewCoNo array
    //const selectedCoNoObject = viewCoNo.find(item => item.coNo === selectedCoNo);
  
    if (selectedCoNoObject) {
      // Update editedDevice state with selected coNo and its corresponding companyWeb
      setEditDevice(prevDevice => ({
        ...prevDevice,
        coNo: selectedCoNo,
        companyWeb: selectedCoNoObject.companyWeb // Set companyWeb based on selected coNo
      }));
    } else {
      // Handle the case where the selected coNo object is not found
      console.error('Selected coNo object not found');
    }
  
    // After updating, switch back to view mode
    setEditableField(null);
  };
  

  const handleSiteUpdateClick = () => {
    // Update editedDevice state
    setEditDevice(prevDevice => ({
      ...prevDevice,
      site: selectedSite,
      // coNo: selectedCoNo
    }));
 
    // After updating, switch back to view mode
    setEditableField(null);
  };
  

  const handleInputChange = (e) => {
    // Update the editedSite state when input values change
    const { name, value } = e.target;
    setEditDevice((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update editedSite when the site prop changes
    setEditDevice({ ...device });
  }, [device]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
      <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='SitesZones-title'>Edit Device</h3>
          </div>
            <div className='defaultBtnCont'> 
            <span className='defaultBtn' onClick={handleUpdateSubmit}>Save</span>
            </div>
            
          <div onClick={onClose} className='closeContAllModals' >
              <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company No:</span>
        </div>
          
          {editableField === 'coNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
              <select
                  id="editDeviceDropdown"
                  value={selectedCoNo}
                  onChange={(e) => {
                    setSelectedCoNo(e.target.value);
                    }}
                >
                  <option value="">-- Select --</option>
                  {viewCoNo.map((item) => (
                    <option key={item.id} value={item.coNo}>
                      {item.coNo}
                    </option>
                  ))}
                </select>
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedDevice.coNo}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coNo')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Web:</span>
        </div>
          
          {editableField === 'companyWeb' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="companyWeb"
                value={selectedCoNoObject.companyWeb}
                //onChange={handleInputChange}
              />
              </div>              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedDevice.companyWeb}</span>
              </div>
            </div>
          )}
        </div>
       <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Device ID:</span>
        </div>
          
          {editableField === 'deviceId' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="deviceId"
                value={editedDevice.deviceId}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedDevice.deviceId}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('deviceId')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Device Name:</span>
        </div>
          
          {editableField === 'deviceName' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="deviceName"
                value={editedDevice.deviceName}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedDevice.deviceName}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('deviceName')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Site:</span>
        </div>
          
          {editableField === 'site' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
              {isLoadingSites ? (
                <div>Loading sites...</div>
                  ) : (
                    <select
                      id="editDeviceDropdown"
                      value={selectedSite}
                      onChange={(e) => setSelectedSite(e.target.value)}
                    >
                      <option value="">-- Select --</option>
                      {viewSites.map((item) => (
                        <option key={item.id} value={item.site}>
                          {item.site}
                        </option>
                      ))}
                    </select>
                  )}
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleSiteUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedDevice.site}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('site')}
                />
              </div>
             
            </div>
          )}
        </div>             
      </div>
    </div>
  );
};

export default EditDeviceModal;
