import React, { Component } from 'react';
import { FaExclamation } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      isOpen: props.isOpen, // Initialize isOpen state with the value from props
      message: '',
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by error boundary:', error, errorInfo);
    this.setState({ isOpen: true, message: 'Something went wrong. Please try again later or contact Worxit support.' });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
    this.props.onClose(); // Call onClose prop when the toast is closed
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState(prevState => ({ progress: prevState.progress - 4 }));
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps) {
    // Update isOpen state when props change
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  render() {
    const { hasError, isOpen, message } = this.state;

    if (hasError && isOpen) { // Check if hasError and isOpen are both true
      return (
        <div className="errorBoundaryToast-modal">
          <div className="errorBoundaryToast-modal-content">
            <div className="errorBoundaryToast-cell">
              <div className="errorBoundaryToast">
                <div className="errorBoundaryToast-icon">
                  <FaExclamation />
                </div>
                <div className="errorBoundaryToast-content">
                  <p className="errorBoundaryToast-type">Error</p>
                  <p className="errorBoundaryToast-message">{message}</p>
                </div>
                <div className="errorBoundaryToast-close" onClick={this.handleClose}>
                  <MdClose />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
