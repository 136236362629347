import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import SuccessToast from '../../../SuccessToast/SuccessToast';
import './CreateUserModal.css';

const CreateUserModal = ({ isOpen, onClose }) => {
    const [viewCompanyNo, setViewCompanyNo] = useState([]);
    const [selectedCoNo, setSelectedCoNo] = useState('');
    const [message, setMessage] = useState("");
    const [viewCompanyWeb, setViewCompanyWeb] = useState([]);
    const [selectedCompanyWeb, setSelectedCompanyWeb] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [showSuccessToast, setShowSuccessToast] = useState(false);

    const headerData = useRecoilValue(AtomHeaderDataState);
    const userCompanyWeb = headerData[0].companyWeb;
    const usercoNoValue = headerData[0].coNo;

    const [formData, setFormData] = useState({
        username: '',
        role: '',
        password: '',
        confirmPassword: '',
        empNo: ''
        // Add other fields as needed
    });

    useEffect(() => {
        const fetchCompanyNos = async () => {
            try {
                const response = await axios.get(`https://myworxit.co.za/api/fetchCompanyNo.php`);
                const newData = response.data.records;
                setViewCompanyNo(newData);
                console.log('newData: ', newData)
              } catch (error) {
                console.error("Error fetching data: ", error);
              }
        };
    
        fetchCompanyNos(); // Initial call
        const intervalId = setInterval(fetchCompanyNos, 5000);
        return () => clearInterval(intervalId);
      }, [setViewCompanyNo]); // Empty dependency array to run this effect only once

    useEffect(() => {
      const fetchCompanyWeb = async () => {
        try {
          const response = await axios.get(`https://myworxit.co.za/api/fetchCompanyWeb.php`);
          const newData = response.data.records;
          
          // const filteredCompanyWeb = newData.filter((data) => selectedCoNo === data.coNo);
          
          // console.log('filteredCompanyWeb: ', filteredCompanyWeb)
          
          setViewCompanyWeb(newData);
          
          console.log('newData: ', newData);
      } catch (error) {
          console.error("Error fetching data: ", error);
      }
    };
    
      fetchCompanyWeb(); // Initial call
      const intervalId = setInterval(fetchCompanyWeb, 5000);
      return () => clearInterval(intervalId);
    }, [setViewCompanyWeb, selectedCoNo]); // Empty dependency array to run this effect only once


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };
    
   
    const handleSubmit=(e)=>{
      e.preventDefault();
    
     const sendData={
        
        coNo: userCompanyWeb === 'worxit' ? selectedCoNo : usercoNoValue,
        companyWeb: userCompanyWeb === 'worxit' ? selectedCompanyWeb : userCompanyWeb,
        username:formData.username,
        password:formData.password,
        confirmPassword:formData.confirmPassword,
        empNo:formData.empNo,
        role:selectedRole,

      }
      console.log(sendData)
              
      axios.post(`https://myworxit.co.za/api/createUsers.php`,sendData)
      .then((res)=> {
        if (res.data.success) {
          setMessage(res.data.message);
          setShowSuccessToast(true);
          //onClose();  // Close the modal only if the response is true
        } else {
         setMessage(res.data.message);
        }
      })
      .catch((res) => {
        console.error(res);
        setMessage(res.data.message);
       
      });
    }

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-user-modal">
      <div className="create-user-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-user-title">Create User</h3>
          </div>
            <div className='defaultBtnCont'>
              <span onClick={handleSubmit} className='defaultBtn' type="submit">Add User</span>
            </div>
            <div onClick={onClose} className="closeContAllModals">
              <span className="closeAllModalIcon">
                <FaTimes />
              </span>
          </div>
        </div>
          <div className='createUserSelectedCoNoCont'>
              <div className='createUserCompanyNoTitle'>
                <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Company No:</label>
              </div>
              {userCompanyWeb === 'worxit' ? (
                <div className='dropdownCompanyNoCont'>
                <select
                id="CreateDeviceCompanyNoDropdown"
                value={selectedCoNo}
                onChange={(e) => setSelectedCoNo(e.target.value)}
              >
                <option value="">-- Select --</option>
                {viewCompanyNo.map((item) => (
                  <option key={item.id} value={item.coNo}>
                    {item.coNo}
                  </option>
                ))}
              </select>
            </div>
              ): (
                <div className='usertitleCon'>
                    <label className='usertitleLbl'>
                      {usercoNoValue}
                    </label> 
                  </div>
              )}
            </div>
            
              <div className='createDeviceSelectedCoNoCont'>
                <div className='createDeviceCompanyNoTitle'>
                    <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Company Web:</label>
                </div>
                {userCompanyWeb === 'worxit' ? (
                  <div className='dropdownCompanyNoCont'>
                      <select
                      id="CreateDeviceCompanyNoDropdown"
                      value={selectedCompanyWeb}
                      onChange={(e) => setSelectedCompanyWeb(e.target.value)}
                    >
                      <option value="">-- Select --</option>
                      {viewCompanyWeb.map((item) => (
                        <option key={item.id} value={item.companyWeb}>
                          {item.companyWeb}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className='usertitleCon'>
                    <label className='usertitleLbl'>
                      {userCompanyWeb}
                    </label> 
                  </div>
                )}
              </div>
           
          
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Username:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Password:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Confirm Password:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserEmpNoCont'>
              <div className='createUserEmpNoTitle'>
                <label className='searchName'>
              Employee No:
              </label>
              </div>
              <div className='createUserEmpNo'>
                <input
                className='createUserEmpNoInput'
                type="text"
                name="empNo"
                value={formData.empNo}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createUserRoleCont'>
            <div className='createUserRoleTitle'>
              <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Role:</label>
            </div>r
            <div className='dropdownCompanyNoCont'>
              <select
                id="CreateDeviceCompanyNoDropdown"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}

              >
                <option value="">-- Select --</option>
                <option value="2">Admin</option>
                <option value="3">Manager</option>
                <option value="4">Supervisor</option>
                <option value="5">User</option>
              </select>
            </div>            
          </div>
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>   
            
              <SuccessToast 
                  message={message} 
                  isOpen={showSuccessToast} 
                  onToastClose={() => setShowSuccessToast(false)} 
                  onClose={() => onClose()} 
                />
              

      </div>
    </div>
  );
};

export default CreateUserModal;
