import React, {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
import CreateCompanyModal from '../CreateCompanyModal/CreateCompanyModal';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { AtomRolesState } from '../../../../RecoilState/AtomRolesState';
import { useRecoilValue } from 'recoil';
import EditCompanyModal from '../EditCompanyModal/EditCompanyModal';
import './CompanyModal.css';


const CompanyModal = ({ isOpen, onClose }) => {
  
  const [viewCompany, setViewCompany]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [message, setMessage] = useState("");
  const [isCreateCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
  const headerData = useRecoilValue(AtomHeaderDataState);
  const roleLevel = useRecoilValue(AtomRolesState);
  const userRoleValue = headerData[0].role;
  const roleValue = roleLevel.role[userRoleValue];
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false) 

  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [sortConfig, setSortConfig] = useState({ column: null, direction: 'ascending' });

  const companyTable = async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/viewCompany.php`);
      const newData = response.data.records;
      setViewCompany(newData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
  
useEffect(() => {
  companyTable(); // Initial call
  const intervalId = setInterval(companyTable, 3000);
  return () => clearInterval(intervalId);
}, [setViewCompany]); // Empty dependency array to run this effect only once
  
  
  const filteredSearch = viewCompany.filter((user) => {
    if (searchColumn !== "") {
      return user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase());
    }
    // If "All" is selected, check if any column contains the search keyword
    return Object.values(user).some((value) =>
      value.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  });
    
  useEffect(() => {
    if (filteredSearch.length === 0 && searchKeyword !== "") {
      setMessage("No Match Found");
    } else {
      setMessage("");
    }
  }, [filteredSearch, searchColumn, searchKeyword]);


  const editForm = (company) => {
    setSelectedCompany(company);
    setIsEditCompanyModalOpen(true);
  };

  const handleModalClose = () => {
    // Reset pagination and refetch data when modal is closed
    setPageNumber(0);
    companyTable();
    onClose();
  }; 

  /*-----------PAGGINATION-------------*/
  const [pageNumber, setPageNumber] = useState(0);
  const reportsPerPage = 5; // Set the number of reports to display per page
  const pagesVisited = pageNumber * reportsPerPage;
  const pageCount = Math.ceil(filteredSearch.length / reportsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

     // Function to handle sorting
const handleSort = (column) => {
  // Toggle sorting order
  setIsSortingAsc((prev) => !prev);
  let direction = isSortingAsc ? 'ascending' : 'descending';
  setSortConfig({ column, direction });
};


// Function to sort data based on column and direction
const sortedData = useMemo(() => {
  let sortedUsers = [...filteredSearch];
  if (sortConfig.column !== null) {
    sortedUsers.sort((a, b) => {
     
      if (a[sortConfig.column] > b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      if (a[sortConfig.column] < b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      return 0;
    });
  }
  return sortedUsers;
}, [filteredSearch, sortConfig]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="company-modal">
      <div className="company-modal-content">
      <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-device-title">Company</h3>
          </div>
          {roleValue.btnAdd === '1' ? (
             <div className='defaultBtnCont'>
              <span onClick={() => setCreateCompanyModalOpen(true)} className='defaultBtn' type="submit">Create</span>
             </div>
            ) : null
          }
          <div onClick={handleModalClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="coNo">Company No</option>
              <option value="coName">Company Name</option>
              <option value="coReg">Company Reg</option>
              <option value="coContact">Company Contact</option>
              <option value="coAdd">Company Address</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        {/* {roleValue[0]?.role === 0 ? (
            <div className='createCompanyBtnCont'>
              <button className='createCompanybtn' onClick={() => setCreateCompanyModalOpen(true)}>Create</button>
            </div>
            ) : null
          } */} 
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
        <thead>
            <tr className={`filterSortColumns ${isSortingAsc ? 'open' : ''}`}>
              <th onClick={() => handleSort('coNo')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Company No
                  {sortConfig.column === 'coNo' && (
                    <div className="arrow"></div>
                    )}
                </div>
              </th>
                <th onClick={() => handleSort('coName')} className="filterSortColumns-toggle">
                  <div className='filterSortColumnsHeader'>
                    Company Name
                    {sortConfig.column === 'coName' && (
                      <div className="arrow"></div>
                      )}
                  </div>
                </th>             
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : sortedData.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  sortedData
                  .slice(pagesVisited, pagesVisited + reportsPerPage)
                    .map((viewCompany, index) => (
                      <tr onClick={() => editForm(viewCompany)} key={index}>
                        <td>{viewCompany.coNo}</td>
                        <td>{viewCompany.coName}</td>
                        <td>
                          <div
                            className={`statusCircle ${
                              viewCompany.status === '1' ? 'green' : 'red'
                            }`}
                          ></div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
                previousLabel={'<<'}
                nextLabel={'>>'}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
              </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>} 
        {isCreateCompanyModalOpen && (
          <CreateCompanyModal 
            isOpen={isCreateCompanyModalOpen} 
            onClose={() => setCreateCompanyModalOpen(false)} />
        )}
        {selectedCompany && (
          <EditCompanyModal
            isOpen={isEditCompanyModalOpen}
            onClose={() => setIsEditCompanyModalOpen(false)}
            company={selectedCompany}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyModal;
