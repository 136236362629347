import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { AtomAccessControlDataState } from '../../../RecoilState/AtomAccessControlDataState';
import { AtomAccessControlNamesState } from '../../../RecoilState/AtomAccessControlNamesState';
import './AccessControlModal.css';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import AccessControlDetailsModal from './AccessControlDetailsModal';
import ReportsLoader from './AccessControlLoader/ReportsLoader';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns';
import ReactPaginate from 'react-paginate';
import { FaTimes } from 'react-icons/fa';
import { LiaTimesSolid } from "react-icons/lia";
import { CgPlayListAdd } from "react-icons/cg";
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';
import ViewSelectedFieldNamesModal from './ViewSelectedFieldNamesModal/ViewSelectedFieldNamesModal';
import ExcelJS from 'exceljs';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

const AccessControlModal = ({ isOpen, onClose }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [viewAccessControlFormNames, setViewAccessControlFormNames] = useRecoilState(AtomAccessControlDataState);
  const [viewAccessControlFormData, setViewAccessControlFormData] = useRecoilState(AtomAccessControlNamesState)
  const resetViewFormData = useResetRecoilState(AtomAccessControlNamesState)
  const [viewSites, setViewSites] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState([])
  const [selectedFieldNameModal, setSelectedFieldNamesModal] = useState(false)
  const [isErrorBoundaryOpen, setisErrorBoundaryOpen]  = useState(false)
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const [searchSite, setSearchSite] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [pageNumber, setPageNumber] = useState(0);
  const reportsPerPage = 10; // Set the number of reports to display per page
  const pagesVisited = pageNumber * reportsPerPage;

  const [selectedFields, setSelectedFields] = useState([]);
  const [reportsColumns, setReportsColumns] = useState([]); // State to store added columns

 
  const selectedFieldsMap = {};
  selectedFields.forEach((fieldName, index) => {
  selectedFieldsMap[fieldName] = index;
});

// Modify the displayReports mapping function to use the selectedFieldsMap
const displayReports = filteredReports
  .sort((a, b) => new Date(b.create_on) - new Date(a.create_on))
  .slice(pagesVisited, pagesVisited + reportsPerPage)
  .map((report) => {
    const reportData = JSON.parse(report.reportData);

    // Map additional data to the selected fields
    const additionalDataMap = {};
    reportData.data.forEach(item => {
      if (selectedFields.includes(item.fieldName)) {
        if (typeof item.fieldData === 'object') {
          additionalDataMap[item.fieldName] = JSON.stringify(item.fieldData);
        } else {
          additionalDataMap[item.fieldName] = item.fieldData;
        }
      }
    });

    // Render table row with additional data in the correct columns
    return (
      <tr className='selectReport' key={report.id} onClick={() => handleReportClick(report.reportId)}>
        <td>{report.reportId}</td>
        <td>{report.reportName}</td>
        <td>{report.create_on}</td>
        {selectedFields.map((fieldName, index) => (
          <td key={index}>{additionalDataMap[fieldName]}</td>
        ))}
      </tr>
    );
  });

  console.log('filteredReports: ', filteredReports)

  const pageCount = Math.ceil(filteredReports.length / reportsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const fetchCreatedFormNames = async () => {
      try {
        const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/ViewAccessFormNames.php`);
        const newData = response.data.records;
        setViewAccessControlFormNames(newData);
      } catch (error) {
        // console.error("Error fetching data: ", error);

      }
    };

    fetchCreatedFormNames(); // Initial call
    const intervalId = setInterval(fetchCreatedFormNames, 5000);
    return () => clearInterval(intervalId);
  }, [setViewAccessControlFormNames ,companyWebValue]); // Empty dependency array to run this effect only once

  // useEffect(() => {
    const fetchCreatedFormData = async () => {
      try {
        const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/ViewAccessFormData.php`);
        const newData = response.data.records;
        setViewAccessControlFormData(newData);
        console.log('viewFormData-----------: ', viewAccessControlFormData)
      } catch (error) {
        // console.error("Error fetching data: ", error);

      }
    };

  //   fetchCreatedFormData(); // Initial call
  //   const intervalId = setInterval(fetchCreatedFormData, 5000);
  //   return () => clearInterval(intervalId);
  // }, [setViewFormData ,companyWebValue]); // Empty dependency array to run this effect only once

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewSites.php`);
        const newData = response.data.records;
        setViewSites(newData);
      } catch (error) {
        // console.error("Error fetching data: ", error);

      }
    };

    fetchSites(); // Initial call
    const intervalId = setInterval(fetchSites, 5000);
    return () => clearInterval(intervalId);
  }, [setViewSites, companyWebValue]); // Empty dependency array to run this effect only once

  const { subDays } = require('date-fns');

  const handleSearch = async () => {

    let fromDate = '';
        let toDate = '';
        
        if (!dateRange || !dateRange[0]) {
            // If no date range is specified, fetch reports for the last 30 days
            fromDate = format(subDays(new Date(), 30), 'yyyy-MM-dd 00:00:00');
            toDate = format(new Date(), 'yyyy-MM-dd 23:59:59');
        } else {
            fromDate = format(dateRange[0], 'yyyy-MM-dd 00:00:00');
            toDate = format(dateRange[1], 'yyyy-MM-dd 23:59:59');
        }
   
      try {
          const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/ViewAccessReportData.php`, {
            params: {
              create_from: dateRange && dateRange[0]
                ? format(dateRange[0], 'yyyy-MM-dd 00:00:00') // Set time to start of the day
                  : '',
              create_to: dateRange && dateRange[1]
                ? format(dateRange[1], 'yyyy-MM-dd 23:59:59') // Set time to end of the day
                  : '',
          reportName: selectedFieldName,
          searchSite: searchSite,
          searchValue: searchValue,
          selectedFields: selectedFields,
            },
          });
          // console.log('selectedFields --------------: ', selectedFields)
          // console.log("API Response: ", response.data); // Add this line to check the response structure
  
          const newData = response.data.records;
          setFilteredReports(newData);
          fetchCreatedFormData();
          const selectedFieldData = newData.flatMap(record => {
            try {
                const reportData = JSON.parse(record.reportData);
                const data = reportData.data || [];
                return data.filter(item => selectedFields.includes(item.fieldName))
                           .map(item => ({ fieldName: item.fieldName, fieldData: item.fieldData }));
            } catch (error) {
                // console.error('Error parsing reportData:', error);
                return []; // Return an empty array if there's an error parsing reportData
            }
        });
        
        
        // console.log('Selected Field Data:', selectedFieldData);
          
          setReportsColumns(selectedFieldData)

                  
          setIsLoading(false); 
          setSearchClicked(true);
          setPageNumber(0);
        
      } catch (error) {
        // console.error("Error fetching data: ", error);
        setIsLoading(false); 
      }
     };

  const handleReportClick = (reportId) => {
    setSelectedReportId(reportId);
  };

  
   const resetData = () => {
    setFilteredReports([]);
    setSelectedFieldName('');
    setSearchSite('');
    setSearchValue('');
    setSelectedReportId(null);
    setDateRange([null, null]);
    setPageNumber(0);
    setSearchClicked(false);
    setSelectedFields([]);
    setReportsColumns([]);
    setViewAccessControlFormData([])
   resetViewFormData([]);
  };

  const handleCloseModal = () => {
    onClose();
    resetData();
  };

  if (!isOpen) {
    return null;
  }

  // console.log('dateRange: ', dateRange)
  // console.log('filteredReports: ', filteredReports)

  const filteredForms =  viewAccessControlFormNames.filter((item) => item.elementId === 'et');
  
  console.log('filteredForms: ', filteredForms)

  const exportToExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reports');
  
    // Add headers
    const headers = ['Report ID', 'Report Name', 'Date', ...selectedFields];
    worksheet.addRow(headers);
  
    // Add data
    filteredReports.forEach(report => {
      const reportData = JSON.parse(report.reportData);
  
      // Map additional data to the selected fields
      const additionalDataMap = {};
      reportData.data.forEach(item => {
        if (selectedFields.includes(item.fieldName)) {
          if (typeof item.fieldData === 'object') {
            additionalDataMap[item.fieldName] = JSON.stringify(item.fieldData);
          } else {
            additionalDataMap[item.fieldName] = item.fieldData;
          }
        }
      });
  
      // Create rowData array with report data and additional data
      const rowData = [
        report.reportId,
        report.reportName,
        report.create_on,
        ...selectedFields.map(fieldName => additionalDataMap[fieldName]) // Use selectedFieldsMap to get additional data in correct order
      ];
  
      // Add rowData to worksheet
      worksheet.addRow(rowData);
    });
  
    // Save the workbook
    workbook.xlsx.writeBuffer().then(buffer => {
      // Create a Blob from the buffer
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'reports.xlsx';
      // Trigger the download
      a.click();
      // Release the object URL
      URL.revokeObjectURL(url);
    });
  };

  const onCloseErrorBoundary = () =>{
    setisErrorBoundaryOpen(false)
    setSelectedReportId(null)
  }
     
  return (
    <div className="reports-modal">
      <div className="reports-modal-content">
        <div className='reportHeaderCont'>
          <div className='headerTitle'>
              <h3>Access Control</h3>
            </div>
            <div onClick={handleCloseModal} className='closeContReportModal' >
            <span className='closeReportModalIcon'>
              <FaTimes />
            </span>
          </div>
          </div>
        <div className='searchCont'>
          <div className='searchTitleColumn'>
            <label className='searchTitle'>Search</label>
          </div>
          <div className='searchColumn'>
            <div className='searchDateCont'>
              <div className='searchNameCont'>
                <label className='searchName'>Date:</label>
              </div>
              <div className='searchDate'>
              <DateRangePicker
                className='dateInput'
                value={dateRange}
                onChange={(value) => {
                  // console.log('Selected date range:', value);
                  setDateRange(value);
                }}
                onClean={resetData}
                placeholder={"Search Date Range"}
                
              />
            </div>
          
            </div>
           
            <div className='searchReportNameCont'>
              <div className='searchNameCont'>
                <label className='searchName' htmlFor="searchDropdown">Site:</label>
              </div>
              <div className='dropdownSearchCont'>
                <select
                id="searchDropdown"
                value={searchSite}
                onChange={(e) => setSearchSite(e.target.value)}
              >
                <option value="">-- Select --</option>
                {viewSites.map((item) => (
                  <option key={item.id} value={item.site}>
                    {item.site}
                  </option>
                ))}
              </select>
              </div>
              {/* <div onClick={resetData} className='clearSiteDropCont' >
                <span className='clearSiteDropContIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div> */}
            </div>
            
            <div className='searchAnythingCont'>
              <div className='searchNameCont'>
                <label className='searchName' htmlFor="searchDropdown">Find</label>
              </div>
              <div className='searchAnything'>
                 <input
                    className='inputSearchAnythingComponent'
                    type="text"
                    placeholder='Search for anything'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
              />
              </div>
              {/* <div onClick={resetData} className='clearSearchAnythingCont' >
                <span className='clearSearchAnythingIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div> */}
            </div>
          </div>
          <div className='searchColumn2'>
          <div className='searchReportNameCont'>
              <div className='searchNameCont'>
                <label className='searchName' htmlFor="searchDropdown">Report Name:</label>
              </div>
              <div className='dropdownSearchCont'>
                <select
                id="searchDropdown"
                value={selectedFieldName}
                onChange={(e) => {
                  // Find the selected form object based on the fieldName
                  const selectedFormName = filteredForms.find(item => item.fieldName === e.target.value);
          
                  // Access and use the formId
                  if (selectedFormName) {
                    const selectedFormId = selectedFormName.formId;
                    // Do something with the selectedFormId, such as storing it in state
                    setSelectedFieldName(e.target.value);
                    fetchCreatedFormData();
                    setSelectedFormId(selectedFormId)
                   // console.log('selectedFormId: ', selectedFormId)
                  }
                }}              >
                <option value="">-- Select --</option>
                {filteredForms.map((item) => (
                  <option key={item.id2} value={item.fieldName}>
                    {item.fieldName}
                  </option>
                ))}
              </select>
              </div>
              {/* <div onClick={resetData} className='clearReportNameDropCont' >
                <span className='clearReportNameDropIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div> */}
            </div>
            <div className='searchReportNameCont'>
              <div className='searchNameCont'>
                <label className='searchName'>Additional Info:</label>
              </div>
              <div className='listAddCont'>
                <span  onClick={() => setSelectedFieldNamesModal(true)} className='CgPlayListAddIcon'>
                  <CgPlayListAdd className='CgPlayListAdd'/>
                </span>
              </div>
              {/* <div onClick={resetData} className='clearFieldNameCont' >
                <span className='clearFieldNameIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div> */}
            </div>
          </div>
          <div className='searchBtnCont'>
            <button className='clearBtn' onClick={resetData}>Clear</button>
            <button className='searchBtn' onClick={handleSearch}>Search</button>
          </div>
        </div>
        
          
          {searchClicked && filteredReports.length > 0 ? (
              <>
            {isLoading ? (
              <div className='reportModalLoaderCont'>
                < ReportsLoader />
              </div>
            ): (
              <div className='reportsTableCont'>
                <table className='reportsTable'>
                  <thead>
                    <tr>
                      <th>Report ID</th>
                      <th>Report Name</th>
                      <th>Date</th>
                      {/* <th>{selectedFields}</th> */}
                      {/* {tableHeaders} */}
                      {selectedFields.map((fieldName, index) => (
                        <th key={index}>{fieldName}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody >{displayReports}</tbody>
                </table>
                <ReactPaginate
                  previousLabel={'<<'}
                  nextLabel={'>>'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
              )}
                <div className='exportToExcelCon'>
                 <button className='searchBtn' onClick={exportToExcel}>Export Summary Report</button>
                </div>

              </>
            ) : searchClicked ? (
              <div className='noRecordFoundCont'>
                <label className='searchName'> No records found</label>
              </div>
            ) : null}

        <ErrorBoundary
              isOpen={isErrorBoundaryOpen}
              onClose={onCloseErrorBoundary}
              >
        {selectedReportId && (
          <AccessControlDetailsModal 
            reportId={selectedReportId} 
            onClose={() => setSelectedReportId(null)} />
        )}

        {selectedFieldNameModal && (
          <ViewSelectedFieldNamesModal 
            isOpen={selectedFieldNameModal} 
            onClose={() => setSelectedFieldNamesModal(false)} 
            selectedFormId={selectedFormId}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            setReportsColumns={setReportsColumns}
          />
        )}
    </ErrorBoundary>
      </div>
    </div>
  );
};

export default AccessControlModal;
