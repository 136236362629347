import React from 'react';
import { useReactToPrint } from 'react-to-print';

class PrintableComponent extends React.Component {
  render() {
    const { reportDetails, filteredData } = this.props;

    return (
      <div>
        {/* Render content similar to your PDF */}
        {/* Include report details */}
        {reportDetails.map(report => (
          <div key={report.id}>
            <p>Report Name: {report.reportName}</p>
            {/* Add other report details as needed */}
          </div>
        ))}
        
        {/* Include filtered data */}
        <table>
          <tbody>
            {filteredData.map(field => (
              <tr key={field.id}>
                <td>{field.fieldName}</td>
                <td>{field.fieldData}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

// Create a wrapper component for React-to-Print
const PrintWrapper = ({ reportDetails, filteredData }) => {
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <PrintableComponent ref={componentRef} reportDetails={reportDetails} filteredData={filteredData} />
      <button onClick={handlePrint}>Print</button>
    </div>
  );
};

export default PrintWrapper;
