import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import './CreateRolesModal.css';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';

const CreateRolesModal = ({ isOpen, onClose }) => {
    const headerData = useRecoilValue(AtomHeaderDataState)
    const coNoValue = headerData[0].coNo;
    const companyWebValue = headerData[0].companyWeb;
    const [viewCompanyNo, setViewCompanyNo] = useState([]);
    const [selectedCoNo, setSelectedCoNo] = useState('');
    const [viewUsernames, setViewUsernames] = useState([]);
    const [selectedUsernames, setSelectedUsernames] = useState('');
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        role: '',
        // Add other fields as needed
    });

    useEffect(() => {
        const fetchCompanyNos = async () => {
            try {
              const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/fetch/fetchCompanyNo.php`);
              const newData = response.data.records;
              setViewCompanyNo(newData);
              console.log('newData: ', newData)
              } catch (error) {
                console.error("Error fetching data: ", error);
              }
        };
    
        fetchCompanyNos(); // Initial call
        const intervalId = setInterval(fetchCompanyNos, 5000);
        return () => clearInterval(intervalId);
      }, [setViewCompanyNo]); // Empty dependency array to run this effect only once

      useEffect(() => {
        const fetchUsernames = async () => {
            try {
              const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/fetch/fetchUsername.php`);
              const newData = response.data.records;
              setViewUsernames(newData);
              console.log('newData: ', newData)
              
              } catch (error) {
                console.error("Error fetching data: ", error);
              }
        };
    
        fetchUsernames(); // Initial call
        const intervalId = setInterval(fetchUsernames, 5000);
        return () => clearInterval(intervalId);
      }, [setViewUsernames]); // Empty dependency array to run this effect only once

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit=(e)=>{
      e.preventDefault();
  
      // if (data.password !== data.confirmPassword) {
      //   setMessage("Passwords do not match.");
      // return;
      // }
  
     const sendData={
        coNo:coNoValue,
        username:selectedUsernames,
        role:formData.role,
      }
      console.log(sendData)
     
      const apiUrl = `https://myworxit.co.za/api/${companyWebValue}/create/createRoles.php`;
     
      axios.post(apiUrl, sendData)
      .then((res)=> {
        if (res.data.success) {
          onClose();  // Close the modal only if the response is true
        } else {
          setMessage(res.data.message);
        }
        // setMessageSuc(res.data.messageSuc);
        // setMessageError("");
        // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
      })
      .catch((res) => {
        console.error(res);
        setMessage(res.data.message);
       
      });
    }

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-role-modal">
      <div className="create-role-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-role-title">Create Roles</h3>
          </div>
          <div className='defaultBtnCont'>
            <span onClick={handleSubmit} className='defaultBtn' type="submit">Add Role</span>
          </div>
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
        </div>
        </div>           
            <div className='createUserSelectedCoNoCont'>
              <div className='createUserCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateUserCompanyNoDropdown">Company No:</label>
              </div>
              <div className='createUserRoleTitle'>
                <label className='searchName'>
              {coNoValue}
              </label>
              </div>
              {/* <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateUserCompanyNoDropdown"
                  value={selectedCoNo}
                  onChange={(e) => setSelectedCoNo(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewCompanyNo.map((item) => (
                    <option key={item.id} value={item.coNo}>
                      {item.coNo}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className='createUserSelectedZoneCont'>
              <div className='createUserZoneTitle'>
                <label className='searchName' htmlFor="searchZoneDropdown">Username:</label>
              </div>
              <div className='dropdownZoneCont'>
                  <select
                  id="searchZoneDropdown"
                  value={selectedUsernames}
                  onChange={(e) => setSelectedUsernames(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewUsernames.map((item) => (
                    <option key={item.id} value={item.username}>
                      {item.username}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='createUserRoleCont'>
              <div className='createUserRoleTitle'>
                <label className='searchName'>
              Roles:
              </label>
              </div>
              <div className='createUserRole'>
                <input
                className='createUserRoleInput'
                type="text"
                name="role"
                value={formData.role}
                onChange={handleChange}
              />
              </div>            
            </div>
            
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>          
      </div>
    </div>
  );
};

export default CreateRolesModal;
