// UserModal.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import axios from 'axios';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { HiViewList } from "react-icons/hi";
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState';
import { AtomFreqCategoryDataState } from '../../../../RecoilState/AtomFreqCategoryDataState';
import { AtomRolesState } from '../../../../RecoilState/AtomRolesState';
import './FreqVisitorsModal.css';
import { useRecoilValue, useRecoilState } from 'recoil';
import AddVisitorModal from '../AddVisitorModal/AddVisitorModal';
import AddCategoriesModal from '../AddCategoriesModal/AddCategoriesModal';
import EditFreqVisitorsModal from '../EditFreqVisitorsModal/EditFreqVisitorsModal';
//import DeleteSiteModal from '../DeleteSiteModal/DeleteSiteModal';
// import EditSiteModal from '../EditSiteModal/EditSiteModal';
// import CreateSiteModal from '../CreateSitesModal/CreateSitesModal';


const FreqVisitorsModal = ({ isOpen, onClose }) => {
  
  const [viewSites, setViewSites]= useState([]); //change this to an atom instead
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isAddVisitorModalOpen, setIsAddVisitorModalOpen] = useState(false);
  const [viewFreqCategories, setViewFreqCategories] = useRecoilState(AtomFreqCategoryDataState);
  const [isAddCategoriesModalOpen, setIsAddCategoriesModalOpen] = useState(false);
  const headerData = useRecoilValue(AtomHeaderDataState)
  const roleLevel = useRecoilValue(AtomRolesState);
  const companyWebValue = headerData[0].companyWeb;
  const userRoleValue = headerData[0].role;
  const roleValue = roleLevel.role[userRoleValue];
  const [isDeleteSiteModalOpen, setDeleteSiteModalOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [isEditFreqVisitorModalOpen, setIsEditFreqVisitorModalOpen] = useState(false)

  const [selectedFreqVisitor, setSelectedFreqVisitor] = useState(null);

  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [sortConfig, setSortConfig] = useState({ column: null, direction: 'ascending' });

  // const [message, setMessage] = useState("");
  const SitesTable = useCallback(async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewFreqVisitors.php`);
      const newData = response.data.records;
      setViewSites(newData);
      setIsLoading(false);
    
    } catch (error) {
      setIsLoading(false);
    }
  }, [companyWebValue, setViewSites]);
    
useEffect(() => {
    SitesTable(); // Initial call
    const intervalId = setInterval(SitesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewSites, SitesTable]); // Empty dependency array to run this effect only once
  
  //console.log('viewSites: ', viewSites)
  // });
  const filteredSearch = viewSites.filter((user) => {
    // Check if the user matches the search criteria
    const matchesSearch =
      searchColumn !== ""
        ? user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase())
        : Object.values(user).some((value) =>
            value.toLowerCase().includes(searchKeyword.toLowerCase())
          );
  
    // // Check if the user matches the role criteria based on the logged-in user's role
    // const matchesRole =
    //   roleValue[0]?.role === 3 ? user.role === '3' : true;
  
  //     console.log('user:', user);
  // console.log('matchesSearch:', matchesSearch);
  //console.log('matchesRole:', matchesRole);
  
    // Combine the search and role criteria
    return matchesSearch 
    //&& matchesRole;
  });
  
  const categoriesData = useCallback(async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewCatgories.php`);
      const newData = response.data.records;
      setViewFreqCategories(newData);
      setIsLoading(false);
    
    } catch (error) {
      setIsLoading(false);
    }
  }, [companyWebValue, setViewFreqCategories]);
    
useEffect(() => {
    categoriesData(); // Initial call
    const intervalId = setInterval(categoriesData, 3000);
    return () => clearInterval(intervalId);
  }, [setViewFreqCategories, categoriesData]); // Empty dependency array to run this effect only once
  
  const deleteForm = (id) => {
    setSiteToDelete(id);
    setDeleteSiteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
        await axios.delete(`https://myworxit.co.za/api/${companyWebValue}/delete/deleteSite.php?id=${siteToDelete}`);
        setDeleteSiteModalOpen(false);
     
    } catch (error) {
      console.error("Error deleting form: ", error);
    }
  };

  const cancelDelete = () => {
    setSiteToDelete(null);
    setDeleteSiteModalOpen(false);
  };

  const editForm = (freqVisitor) => {
    setSelectedFreqVisitor(freqVisitor);
    setIsEditFreqVisitorModalOpen(true);
  };

  const handleModalClose = () => {
    // Reset pagination and refetch data when modal is closed
    setPageNumber(0);
    SitesTable();
    onClose();
  }; 

   /*-----------PAGGINATION-------------*/
   const [pageNumber, setPageNumber] = useState(0);
   const reportsPerPage = 5; // Set the number of reports to display per page
   const pagesVisited = pageNumber * reportsPerPage;
   const pageCount = Math.ceil(filteredSearch.length / reportsPerPage);
 
   const changePage = ({ selected }) => {
     setPageNumber(selected);
   };

       // Function to handle sorting
const handleSort = (column) => {
  // Toggle sorting order
  setIsSortingAsc((prev) => !prev);
  let direction = isSortingAsc ? 'ascending' : 'descending';
  setSortConfig({ column, direction });
};


// Function to sort data based on column and direction
const sortedData = useMemo(() => {
  let sortedUsers = [...filteredSearch];
  if (sortConfig.column !== null) {
    sortedUsers.sort((a, b) => {
     
      if (a[sortConfig.column] > b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      if (a[sortConfig.column] < b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      return 0;
    });
  }
  return sortedUsers;
}, [filteredSearch, sortConfig]);


  if (!isOpen) {
    return null;
  }

  return (
    <div className="Access-modal">
      <div className="Access-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='Access-title'>Freq Visitors</h3>
          </div>
          {/* {roleValue.btnAdd === '1' ? (            
              <div className='defaultBtnCont'> 
                <span className='defaultBtn' onClick={() => setCreateUserModalOpen(true)}>Add Site</span>
              </div>
            ) : null
          }  */}
          <div onClick={handleModalClose} className='closeContAllModals' >
              <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className='btnCont'>
          <div className='categoriesBtnCont'> 
                {/* <span className='categoriesBtn' onClick={() => setCreateUserModalOpen(true)}>Categories</span> */}
                <span class="defaultBtn" onClick={() => setIsAddCategoriesModalOpen(true)}>Categories</span>
          </div>
            <div className='defaultBtnCont'> 
                {/* <span className='defaultBtn' onClick={() => setCreateUserModalOpen(true)}>Add Visitor</span> */}
                <span class="defaultBtn" onClick={() => setIsAddVisitorModalOpen(true)}>Add Visitor</span>
            </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="site">Sites</option>
              <option value="siteAddress">Site Address</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
        <thead>
            <tr className={`filterSortColumns ${isSortingAsc ? 'open' : ''}`}>
              <th onClick={() => handleSort('id')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Id
                  {sortConfig.column === 'id' && (
                    <div className="arrow"></div>
                    )}
                </div>
              </th>
                <th onClick={() => handleSort('name')} className="filterSortColumns-toggle">
                  <div className='filterSortColumnsHeader'>
                  Name
                    {sortConfig.column === 'name' && (
                      <div className="arrow"></div>
                      )}
                  </div>
                </th>            
                <th onClick={() => handleSort('surname')} className="filterSortColumns-toggle">
                  <div className='filterSortColumnsHeader'>
                  Surname
                    {sortConfig.column === 'surname' && (
                      <div className="arrow"></div>
                      )}
                  </div>
                </th>        
                <th onClick={() => handleSort('SA_ID')} className="filterSortColumns-toggle">
                  <div className='filterSortColumnsHeader'>
                  SA_ID
                    {sortConfig.column === 'SA_ID' && (
                      <div className="arrow"></div>
                      )}
                  </div>
                </th>        
                <th onClick={() => handleSort('category')} className="filterSortColumns-toggle">
                  <div className='filterSortColumnsHeader'>
                  Category
                    {sortConfig.column === 'category' && (
                      <div className="arrow"></div>
                      )}
                  </div>
                </th>         
                {roleValue.iconEdit === '1' || roleValue.iconDelete === '1' ? (
                        <th>Action</th>
                      ): null 
                    }
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : sortedData.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  sortedData
                  .slice(pagesVisited, pagesVisited + reportsPerPage)
                    .map((viewSites, index) => (
                      <tr key={index}>
                        <td>{viewSites.id}</td>
                        <td>{viewSites.name}</td>
                        <td>{viewSites.surname}</td>
                        <td>{viewSites.SA_ID}</td>
                        <td>{viewSites.category}</td>
                        {roleValue.iconEdit === '1' || roleValue.iconDelete === '1' ? (
                            <td>
                              <div className='editDeleteIcons'>
                                {roleValue.iconEdit === '1' ? (
                                    <div className='editRow'>
                                      <span title='Edit Site' className='editRowIcon' onClick={() => editForm(viewSites)}>
                                        <FaPencilAlt />
                                      </span>
                                    </div>
                                  ) : null 
                                }
                                {roleValue.iconDelete === '1' ? (
                                    <div className='deleteRow'>
                                      <span title='Delete Site' className='deleteRowIcon' onClick={() => deleteForm(viewSites.id)}>
                                        <FaTimes />
                                      </span>
                                    </div>
                                  ) : null 
                                }
                              </div>
                            </td>
                          ) : null 
                        }
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
                  previousLabel={'<<'}
                  nextLabel={'>>'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
            </div>
            
           {isAddVisitorModalOpen && (
              <AddVisitorModal 
                isOpen={isAddVisitorModalOpen} 
                onClose={() => setIsAddVisitorModalOpen(false)} />
            )}

          {isAddCategoriesModalOpen && (
            <AddCategoriesModal 
              isOpen={isAddCategoriesModalOpen} 
              onClose={() => setIsAddCategoriesModalOpen(false)} />
          )}
        {/*  <DeleteSiteModal
        isOpen={isDeleteSiteModalOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />*/}
     {isEditFreqVisitorModalOpen && (
      <EditFreqVisitorsModal
        isOpen={isEditFreqVisitorModalOpen}
        onClose={() => setIsEditFreqVisitorModalOpen(false)}
        freqVisitor={selectedFreqVisitor}
        
      />
    )} 
      </div>
    </div>
  );
};

export default FreqVisitorsModal;
