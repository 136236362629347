// src/components/Canvas.js
import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { useDrop } from 'react-dnd';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import EditModal from './EditModal';
import DraggableComponents from './DraggableComponent';
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';

import SuccessToast from '../../SuccessToast/SuccessToast';
import InputElement from './Elements/InputElement/InputElement';
import InstructionsElement from './Elements/InstructionsElement/InstructionsElement';
import SubTitleElement from './Elements/SubTitleElement/SubTitleElement';
import QRSingleScanElement from './Elements/QRSingleScanElement/QRSingleScanElement';
import QRMultiScanElement from './Elements/QRMultiScanElement/QRMultiScanElement';
import TwoDSingleScanElement from './Elements/2DSingleScanElement/2DSingleScanElement';
import TwoDMultiScanElement from './Elements/2DMultiScanElement/2DMultiScanElement';
import SADriversLicense2DElement from './Elements/SADriversLicense2DElement/SADriversLicense2DElement';
import ScanVehicleRegElement from './Elements/ScanVehicleRegElement/ScanVehicleRegElement';
import TwoDScanVehicleRegElement from './Elements/2DScanVehicleRegElement/2DScanVehicleRegElement';
import LoggedUserElement from './Elements/LoggedUserElement/LoggedUserElement';
import YesOrNoQuestionsElement from './Elements/YesOrNoQuestionsElement/YesOrNoQuestionsElement';
import FetchGPSElement from './Elements/FetchGPSElement/FetchGPSElement';
import CameraElement from './Elements/CameraElement/CameraElement';
import VideoElement from './Elements/VideoElement/VideoElement';
import SignatureElement from './Elements/SignatureElement/SignatureElement';
// import SiteElement from './Elements/SiteElement/SiteElement';
import DateTimeElement from './Elements/DateTimeElement/DateTimeElement';
import SelfieCameraElement from './Elements/SelfieCameraElement/SelfieCameraElement';
import TextAreaElement from './Elements/TextAreaElement/TextAreaElement';
import DatePickerElement from './Elements/DatePickerElement/DatePickerElement';
import DropdownElement from './Elements/DropdownElement/DropdownElement';
import MovementOut from './Elements/MovementOut/MovementOut';
import MovementTo from './Elements/MovementTo/MovementTo';
import TwoDMovementOut from './Elements/2DMovementOut/2DMovementOut';
import TwoDMovementTo from './Elements/2DMovementTo/2DMovementTo';
import NFCScan from './Elements/NFCScanElement/NFCScanElement';
import InputWithMediaElement from './Elements/InputWithMediaElement/InputWithMediaElement';
import './FormBuilder.css';

const Canvas = ({generateRan2Let2Num, selectedForm, columns, setColumns, showSelectedForm, onRequestClose, activeTabIndex, isAccessControlChecked, setIsAccessControlChecked}) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [editPanelData, setEditPanelData] = useState(null);
  const [editedComponent, setEditedComponent] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [message, setMessage] = useState("");

  const canvasRef = useRef(null);
  // const [isAccessControlChecked, setIsAccessControlChecked] = useState(false)
  let sortedComponents;
  let sortedSelectedForm;

  if (selectedForm && selectedForm.length > 0) {
    sortedSelectedForm = selectedForm.sort((a, b) => a.id - b.id);
  }

  const lastSelectedIdRef = useRef(
    sortedSelectedForm?.length > 0
      ? parseInt(sortedSelectedForm[sortedSelectedForm.length - 1].id) + 1
      : 1
  );

  useEffect(() => {
    if (selectedForm && selectedForm.length > 0) {
      const selectedComponents = selectedForm.map((formElement) => {
        // Extract the necessary properties from the formElement
        const {
          id2,
          id,
          formId,
          elementId,
          fieldName,
          fieldData,
          required
          // other properties...
        } = formElement;

        // Check elementid and create a component based on its value
        let elementName;
        switch (elementId) {
          case 'ei':
            elementName = 'input';
            break;
          case 'eil':
            elementName = 'textarea';
            break;
          case 'ed':
            elementName = 'dateTime';
            break;
          case 'ets':
            elementName = 'subTitle';       
            break;
          case 'eqrs':
            elementName = 'qrSingleScan';
            break;
          case 'eqr':
            elementName = 'qrMultiScan';
            break;
          case 'e2ds':
            elementName = '2dSingleScan';
            break;
          case 'e2d':
            elementName = '2dMultiScan';
            break;
          case 'esadl2d':
            elementName = 'saDriversLicense2D';
            break;
          case 'eqrscandisk':
            elementName = 'scanVehicleReg';
            break;
          case 'e2dscandisk':
            elementName = '2dscanVehicleReg';
            break;
          case 'edrop':
            elementName = 'dropdown';
            break;
          case 'eu':
            elementName = 'loggedUser';
            break;
          case 'ebool':
            elementName = 'yes/noQuestions';
            break;
          case 'egps':
            elementName = 'fetchGps';
            break;
          case 'eurl':
            elementName = 'camera';
            break;
          case 'evid':
            elementName = 'video';
              break;
          case 'esign':
            elementName = 'signature';
              break;
          case 'edp':
            elementName = 'datePicker';
              break;
          case 'eurls':
            elementName = 'selfieCamera';
              break;
          case 'einst':
            elementName = 'instruction';
              break;
          case 'emo':
            elementName = 'movementOut';
              break;
          case 'emt':
            elementName = 'movementTo';
              break;
          case 'emo2d':
            elementName = '2DMovementOut';
              break;
          case 'emt2d':
            elementName = '2DMovementTo';
              break;
          case 'enfc':
            elementName = 'nfcScan';
              break;
          case 'eim':
            elementName = 'inputWithMediaElement';
              break;
          default:
            elementName = 'Edefault';
          // Add other cases for different elementids as needed
        }
       
        const modifiedFieldData = typeof fieldData === 'string' ? fieldData.split(',').map(item => item.trim()) : fieldData;


        return {
          id2,
          id,
          formId,
          elementId,
          elementName,
          fieldName,
          fieldData: modifiedFieldData,
          required
        };
      });

      sortedComponents = selectedComponents.sort((a, b) => a.id - b.id);

      const filteredComponents = sortedComponents.filter(
      (component) => component.elementId !== 'et' && component.elementId !== 'eemail' && component.elementId !== 'epost'
    );


      // Add the selected components to the canvas
      setColumns((prevColumns) => [filteredComponents]);
      

    }
  }, [selectedForm, setColumns]);

  
  const lastIdRef = useRef(4);
 
  const formId = generateRan2Let2Num();
     
  const handleFormNamChange = (e) => {
      setFormNameProperties({
        ...formNameProperties,
        fieldName: e.target.value,
      });
  };

  const handleFormEmailChange = (e) => {
    setFormEmailProperties({
      ...formEmailProperties,
      fieldName: e.target.value,
    });
};

  const handleFormSiteChange = (e) => {
    setFormSiteProperties((prevFormSiteProperties) => ({
      ...prevFormSiteProperties,
      fieldData: e.target.value,
    }));
  };


const [formEmailProperties, setFormEmailProperties] = useState({
  type: 'text',
  id: 2,
  formId: formId,
  elementId: 'eemail',
  // Add other default properties as needed
});
   
  const [formNameProperties, setFormNameProperties] = useState({
    type: 'text',
    id: 1,
    formId: formId,
    elementId: 'et',
    // Add other default properties as needed
  });

  const [formSiteProperties, setFormSiteProperties] = useState({
    type: 'text',
    id: 3,
    formId: formId,
    elementId: 'epost',
    fieldName: 'Site',
    //fieldData: formNameProperties.fieldData,
    // Add other default properties as needed
  });
  
  useEffect(() => {
    if (!selectedForm) {
      setFormNameProperties((prevFormNameProperties) => ({
        ...prevFormNameProperties,
        // Set default properties if showSelectedForm is false
      }));
    } else {
      // Use data from the selectedForm atom when showSelectedForm is true
      if (selectedForm.length > 0) {
        const formNameElement = sortedComponents[0]; // Get the first element from selectedForm
        setFormNameProperties((prevFormNameProperties) => ({
          ...prevFormNameProperties,
          // Update properties based on selectedForm's data
          id2: formNameElement.id2,
          id: formNameElement.id, // For example, update 'id'
          formId: formNameElement.formId, // Update 'formId'
          elementId: formNameElement.elementId, // Update 'elementId'
          fieldName: formNameElement.fieldName
          // Add other properties based on the selectedForm's data
        }));
      }
    }
  }, [selectedForm, showSelectedForm, sortedComponents]);

  useEffect(() => {
    if (!selectedForm) {
      setFormEmailProperties((prevFormEmailProperties) => ({
        ...prevFormEmailProperties,
        // Set default properties if showSelectedForm is false
      }));
    } else {
      // Use data from the selectedForm atom when showSelectedForm is true
      if (selectedForm.length > 0) {
        const formEmailElement = sortedComponents[1]; // Get the first element from selectedForm\
        //if(formEmailElement.elementId === 'eemail'){
           setFormEmailProperties((prevFormEmailProperties) => ({
          ...prevFormEmailProperties,
          // Update properties based on selectedForm's data
          id2: formEmailElement.id2,
          id: formEmailElement.id, // For example, update 'id'
          formId: formEmailElement.formId, // Update 'formId'
          elementId: formEmailElement.elementId, // Update 'elementId'
          fieldName: formEmailElement.fieldName
          // Add other properties based on the selectedForm's data
        }));
        //}
       
      }
    }
  }, [selectedForm, showSelectedForm, sortedComponents]);

  useEffect(() => {
    if (!selectedForm) {
      setFormSiteProperties((prevFormSiteProperties) => ({
        ...prevFormSiteProperties,
        // Set default properties if showSelectedForm is false
      }));
    } else {
      // Use data from the selectedForm atom when showSelectedForm is true
      if (selectedForm.length > 0) {
        const formSiteElement = sortedComponents[2]; // Get the third element from selectedForm
        setFormSiteProperties((prevFormSiteProperties) => ({
          ...prevFormSiteProperties,
          // Update properties based on selectedForm's data
          id2: formSiteElement.id2,
          id: formSiteElement.id,
          formId: formSiteElement.formId,
          elementId: formSiteElement.elementId,
          fieldName: formSiteElement.fieldName,
          fieldData: formSiteElement.fieldData,
          // Add other properties based on the selectedForm's data
        }));
      }
    }
  }, [selectedForm, showSelectedForm, sortedComponents]);
  
  
  console.log('selectedForm: ', selectedForm)
 
  const [, drop] = useDrop((component) => ({
    
    accept: ['input', 'label', 'textarea', 'date', 'dropdown'],
    
    drop: (item) => {
      let elementId;
      switch (item.elementName) {
        case 'input':
          elementId = 'ei';
          break;
        case 'textarea':
          elementId = 'eil';
          break;
        case 'dateTime':
          elementId = 'ed';
          break;
        case 'subTitle':
          elementId = 'ets';
          break;
        case 'qrSingleScan':
          elementId = 'eqrs';
          break;
        case 'qrMultiScan':
          elementId = 'eqr';
          break;
        case '2dSingleScan':
          elementId = 'e2ds';
          break;
        case '2dMultiScan':
          elementId = 'e2d';
          break;
        case 'saDriversLicense2D':
          elementId = 'esadl2d';
          break;
        case 'scanVehicleReg':
          elementId = 'eqrscandisk';
          break;
        case '2dscanVehicleReg':
          elementId = 'e2dscandisk';
          break;
        case 'dropdown':
          elementId = 'edrop';
          break;
        case 'loggedUser':
          elementId = 'eu';
          break;
        case 'yes/noQuestions':
          elementId = 'ebool';
          break;
        case 'fetchGps':
          elementId = 'egps';
          break;
        case 'camera':
          elementId = 'eurl';
          break;
        case 'video':
          elementId = 'evid';
          break;
        case 'signature':
          elementId = 'esign';
           break;
        case 'datePicker':
          elementId = 'edp';
          break;
        case 'selfieCamera':
          elementId = 'eurls';
          break;
        case 'instruction':
          elementId = 'einst';
          break;
        case 'movementTo':
          elementId = 'emt';
          break;
        case 'movementOut':
          elementId = 'emo';
          break;
        case '2DMovementOut':
          elementId = 'emo2d';
          break;
        case '2DMovementTo':
          elementId = 'emt2d';
          break;
        case 'nfcScan':
          elementId = 'enfc';
          break;
        case 'inputWithMediaElement':
          elementId = 'eim';
          break;
        default:
          elementId = 'Edefault'; // or some other default value
      }
      
    if(!showSelectedForm){
  
      const newId = lastIdRef.current;
      lastIdRef.current = newId + 1;
  
      const newComponent = {
        elementName: item.elementName,
        fieldName: item.label,
        type: item.type,
        id: newId,
        formId: formId,
        elementId: elementId,
        fav: '',
        description: "",
        // ... other properties
      };

      setColumns((prevColumns) => {
        const updatedColumns = [...prevColumns];
        updatedColumns[0] = [...(updatedColumns[0] || []), newComponent]; // Add to the first column
        return updatedColumns;
      });
      
    }else{

      const updatedId = lastSelectedIdRef.current;
      lastSelectedIdRef.current = updatedId + 1;

      const updatedFormId = selectedForm[0].formId;

      // console.log('updatedId: ', updatedId)
      // console.log('updatedFormId: ', updatedFormId)
     
      const updatedComponents = {
        id: updatedId,
        formId: updatedFormId,
        elementId: elementId,
        elementName: item.elementName,
        fieldName: item.label,
        type: item.type,
        fav: '',
        description: "",
        // ... other properties
      };

      console.log(`updatedComponents: `, updatedComponents)
      setColumns((prevColumns) => {
            const updatedColumns = [...prevColumns];
            updatedColumns[0] = [...(updatedColumns[0] || []), updatedComponents]; // Add to the first column
            return updatedColumns;
          });
    }
      
    },
}));


  const handleDeleteComponent = (columnIndex, componentIndex) => {
    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      updatedColumns[columnIndex].splice(componentIndex, 1);
      return updatedColumns;
    });
  };

  const handleSelectedDeleteComponent = async (id2, columnIndex, componentIndex) => {
    if(activeTabIndex === 1){
      try {
        await axios.delete(`https://myworxit.co.za/api/${companyWebValue}/delete/deleteAccessFormComponent.php?id2=${id2}`);
      } catch (error) {
        console.error("Error deleting form: ", error);
      }
    }else{
      try {
        await axios.delete(`https://myworxit.co.za/api/${companyWebValue}/delete/deleteFormComponent.php?id2=${id2}`);
      } catch (error) {
        console.error("Error deleting form: ", error);
      }
    }
   

    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      updatedColumns[columnIndex].splice(componentIndex, 1);
      return updatedColumns;
    });
  };
  
  const handleEditClick = (componentType, component, elementName) => {
    setEditPanelData({ elementName: elementName, component });
    setShowEditPanel(true);
    setEditedComponent(component);
  };

  const handleEditModalSave = (newProperties) => {
    setShowEditPanel(false);

    // Update the properties of the edited component
    setColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.map((c) => (c.id === editedComponent.id ? { ...c, ...newProperties } : c))
      )
    );
  };

 
  const handleEditModalUpdate = ( updatedProperties) => {
    setShowEditPanel(false);
    
    setColumns((prevColumns) =>
    prevColumns.map((column) =>
      column.map((c) => (c.id === editedComponent.id ? { ...c, ...updatedProperties } : c))
    )
  );
  };

  const isCanvasEmpty = columns.every((column) => column.length === 0);

  let isRequired
   if(selectedForm){
     isRequired = (component) => {
     return component.required === '1' ? ' * required' : ''; // Returns '*' if the component is required and has the value '1', otherwise ''
   };
   }
  else{
    isRequired = (component) => {
      if (component.required === '0') {
        return null;
      } else if(component.required ){
        return ' * required'; // Returns an empty string if the component is not required
      }
    }    
 };

 
  const handleAccessControlChange = () => {
    setIsAccessControlChecked(!isAccessControlChecked);
  };

      
  const handleSubmit = (e) => {
    e.preventDefault();

    const sendFormNameData = {
      
      id: formNameProperties.id,
      formId: formNameProperties.formId,
      elementId: formNameProperties.elementId,
      fieldName: formNameProperties.fieldName,

    }

    const sendFormEmailData = {
      
      id: formEmailProperties.id,
      formId: formEmailProperties.formId,
      elementId: formEmailProperties.elementId,
      fieldName: formEmailProperties.fieldName,

    }

    const sendFormSiteData = {
      
      id: formSiteProperties.id,
      formId: formSiteProperties.formId,
      elementId: formSiteProperties.elementId,
      fieldName: formSiteProperties.fieldName,
      fieldData: formSiteProperties.fieldData,

    }

    let sendFormData;
    columns.forEach((column) => {
      sendFormData = column.map((component) => ({
     
      id: component.id,
      formId: component.formId,
      elementId: component.elementId,
      fieldName: component.fieldName,
      fieldData: Array.isArray(component.fieldData) ? component.fieldData.join(', ') : component.fieldData,
      description: component.description,
      required: component.required ? 1 : 0,        
    }));
    });
    console.log('sendFormData-----------: ',sendFormData)
    console.log('sendFormSiteData----------: ',sendFormSiteData)

    if(isAccessControlChecked){
      const apiFormTitleUrl = `https://myworxit.co.za/api/${companyWebValue}/create/AccessControl/createFormTitle.php`;
      const apiFormEmailUrl = `https://myworxit.co.za/api/${companyWebValue}/create/AccessControl/createFormEmail.php`;
      const apiFormsUrl = `https://myworxit.co.za/api/${companyWebValue}/create/AccessControl/createForms.php`;
      const apiFormSiteUrl = `https://myworxit.co.za/api/${companyWebValue}/create/AccessControl/createFormSite.php`;
     
      Promise.all([
        axios.post(apiFormTitleUrl, sendFormNameData),
        axios.post(apiFormEmailUrl, sendFormEmailData),
        axios.post(apiFormsUrl, sendFormData),
        axios.post(apiFormSiteUrl, sendFormSiteData),
      ])

      .then((responses) => {
        setMessage("Form Created Successfully")
        setShowSuccessToast(true);
        // alert("Form Created Successfully");
        //onRequestClose(); // Close the modal only if all responses are true
    })
    .catch((err) => {
      console.error(err);
      // Handle error for any of the requests
      // setMessage("An error occurred.");
    });
    } else{
      const apiFormTitleUrl = `https://myworxit.co.za/api/${companyWebValue}/create/createFormTitle.php`;
      const apiFormEmailUrl = `https://myworxit.co.za/api/${companyWebValue}/create/createFormEmail.php`;
      const apiFormsUrl = `https://myworxit.co.za/api/${companyWebValue}/create/createForms.php`;
      const apiFormSiteUrl = `https://myworxit.co.za/api/${companyWebValue}/create/createFormSite.php`;
     
      Promise.all([
        axios.post(apiFormTitleUrl, sendFormNameData),
        axios.post(apiFormEmailUrl, sendFormEmailData),
        axios.post(apiFormsUrl, sendFormData),
        axios.post(apiFormSiteUrl, sendFormSiteData),
      ])

      .then((responses) => {
        setMessage("Form Created Successfully")
        setShowSuccessToast(true);
    })
    .catch((err) => {
      console.error(err);
      // Handle error for any of the requests
      // setMessage("An error occurred.");
    });
    }

    };

    const handleUpdateForm = (e) => {
      e.preventDefault();
  
      const sendFormNameData = {
        id2: formNameProperties.id2,
        id: formNameProperties.id,
        formId: formNameProperties.formId,
        elementId: formNameProperties.elementId,
        fieldName: formNameProperties.fieldName,
      }

      const sendFormEmailData = {
        id2: formEmailProperties.id2,
        id: formEmailProperties.id,
        formId: formEmailProperties.formId,
        elementId: formEmailProperties.elementId,
        fieldName: formEmailProperties.fieldName,
      }
  
      const sendFormSiteData = {
        id2: formSiteProperties.id2,
        id: formSiteProperties.id,
        formId: formSiteProperties.formId,
        elementId: formSiteProperties.elementId,
        fieldName: formSiteProperties.fieldName,
        fieldData: Array.isArray(formSiteProperties.fieldData) ? formSiteProperties.fieldData.join(', ') : formSiteProperties.fieldData
        
      }
  
      let sendFormData;
      columns.forEach((column) => {
        sendFormData = column.map((component) => ({
          id2: component.id2,
          id: component.id,
          formId: component.formId,
          elementId: component.elementId,
          fieldName: component.fieldName,
          fieldData: Array.isArray(component.fieldData) ? component.fieldData.join(', ') : component.fieldData ,
          description: component.description,
          //required: component.required ? 1 : 0,  
          required: component.required || '0',  
      }));
      });
      console.log('sendFormData: ',sendFormData)
      console.log('sendFormSiteData: ',sendFormSiteData)
       
        if(isAccessControlChecked){
          const apiFormTitleUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateAccessFormTitle.php`;
          const apiFormEmailUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateAccessFormEmail.php`;
          const apiFormsUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateAccessForm.php`;
          const apiFormSiteUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateAccessFormSite.php`;
            
        
          Promise.all([
            axios.post(apiFormTitleUrl, sendFormNameData),
            axios.post(apiFormEmailUrl, sendFormEmailData),
            axios.post(apiFormsUrl, sendFormData),
            axios.post(apiFormSiteUrl, sendFormSiteData),
          ])
            .then((responses) => {
                alert("Form Updated Successfully");
                onRequestClose(); // Close the modal only if all responses are true
            })
            .catch((err) => {
              console.error(err);
              // Handle error for any of the requests
              // setMessage("An error occurred.");
            });
        }else{
          const apiFormTitleUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateFormTitle.php`;
          const apiFormEmailUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateFormEmail.php`;
          const apiFormsUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateForm.php`;
          const apiFormSiteUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateFormSite.php`;
            
        
          Promise.all([
            axios.post(apiFormTitleUrl, sendFormNameData),
            axios.post(apiFormEmailUrl, sendFormEmailData),
            axios.post(apiFormsUrl, sendFormData),
            axios.post(apiFormSiteUrl, sendFormSiteData),
          ])
            .then((responses) => {
                alert("Form Updated Successfully");
                onRequestClose(); // Close the modal only if all responses are true
            })
            .catch((err) => {
              console.error(err);
              // Handle error for any of the requests
              // setMessage("An error occurred.");
            });
        }
        
     
      };
  

    
  return (
    <div className='formBuilderCont' id="canvas" ref={canvasRef}>
      <div className='headerCont'>
        <div className='headerCol1'>
          <div className='formNameCol'>
            <div className='formNameCon'>
              <h6 className='formNameTitle'>Form Name: </h6>
            </div>
            <div className='formNameComponentCon'>
              <input
                className='formNameComponent'
                {...formNameProperties}
                placeholder= {'Enter Form Name'}
                value={formNameProperties.fieldName}
                onChange={handleFormNamChange}
              />
            </div>  
               
          </div>
          <div className='emailCol'>
            <div className='formNameCon'>
              <h6 className='formNameTitle'>Email: </h6>
            </div>
            <div className='formNameComponentCon'>
              <input
                className='formNameComponent'
                {...formEmailProperties}
                placeholder= {'Enter Email'}
                value={formEmailProperties.fieldName}
                onChange={handleFormEmailChange}
              />
            </div>            
          </div>
          <div className='siteCol'>
            <div className='formNameCon'>
              <h6 className='formNameTitle'>Site: </h6>
            </div>
            <div className='formNameComponentCon'>
              <input
                className='formNameComponent'
                {...formSiteProperties}
                placeholder= {'Enter Site'}
                value={formSiteProperties.fieldData}
                onChange={handleFormSiteChange}
              />
            </div>            
          </div>
        </div>
        <div className='headerCol2'>
          {!showSelectedForm ? (
            <div className='save-updateCont'>
              <span className='defaultBtn' onClick={handleSubmit}>Save Form</span>
            </div>
          ) : (
            <div className='save-updateCont'>
              <span className='defaultBtn' onClick={handleUpdateForm}>Update Form</span>
            </div>
          )}
            <div onClick={onRequestClose} className='closeContCreateForm'>
              <span className='closeContCreateFormIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
      </div>
      <div className='subHeaderCont'>
        {isAccessControlChecked === true && activeTabIndex === 1 ? (
          <div className='requireContainer'>
          <div className='column1Cont'>
            <label className='labelTitle'>
              Access Control Form:
            </label>
          </div>
          <div className='column2Cont'>
            <div className='requireContainer'>
              <div className='column2Cont'>
                <input
                      type="checkbox"
                      checked={isAccessControlChecked}
                      onChange={handleAccessControlChange}
                      disabled
                    />
              </div>
            </div>

           
          </div>
        </div>   
        ): (
          <div className='requireContainer'>
          <div className='column1Cont'>
            <label className='labelTitle'>
              Access Control Form:
            </label>
          </div>
          <div className='column2Cont'>
            <div className='requireContainer'>
              <div className='column2Cont'>
                <input
                      type="checkbox"
                      checked={isAccessControlChecked}
                      onChange={handleAccessControlChange}
                      
                    />
              </div>
            </div>

           
          </div>
        </div>   
        )}
      
      </div>
      <div className='bodyCont'>
        <div className='bodyCol1'>
          <div className='descCol1'>
              <label className='desciptionLbl'>Below is a view of what your report will look like</label>
          </div>
            <div className='canvasCont'>
              <div ref={drop} className="canvas">
                {isCanvasEmpty && (
                  <div className="canvas-center-text">
                    All elements dropped in this area will be populated at the end of the form
                  </div>
                )}
            <div className="canvas-row">
              {columns.map((column, columnIndex) => (
                <div key={columnIndex} className={`canvas-column `}>
                  {column.map((component, componentIndex ,newId) => (
                    <div
                    key={component.id}
                    className={`canvas-component ${component.isHovered ? 'hovered' : ''} `}
                    onMouseEnter={() => {
                      // Set a flag in the component's state to indicate it's being hovered
                      setColumns((prevColumns) =>
                        prevColumns.map((col, colIndex) =>
                          colIndex === columnIndex
                            ? col.map((c, cIndex) =>
                                cIndex === componentIndex ? { ...c, isHovered: true } : c
                              )
                            : col
                        )
                      );
                    }}
                    onMouseLeave={() => {
                      // Remove the hover flag when the mouse leaves the component
                      setColumns((prevColumns) =>
                        prevColumns.map((col, colIndex) =>
                          colIndex === columnIndex
                            ? col.map((c, cIndex) =>
                                cIndex === componentIndex ? { ...c, isHovered: false } : c
                              )
                            : col
                        )
                      );
                    }}
                  >
                    <InputElement 
                      component={component}
                      isRequired={isRequired}
                      />

                    <InputWithMediaElement 
                      component={component}
                      isRequired={isRequired}
                      />

                    <InstructionsElement 
                      component={component}
                      isRequired={isRequired}
                      />
                    <SubTitleElement 
                      component={component}
                      isRequired={isRequired}
                      />

                    <QRSingleScanElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <QRMultiScanElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <TwoDSingleScanElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <TwoDMultiScanElement 
                      component={component}
                      isRequired={isRequired}
                    />

                  <SADriversLicense2DElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <ScanVehicleRegElement 
                      component={component}
                      isRequired={isRequired}
                    />
                    
                    <TwoDScanVehicleRegElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <LoggedUserElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <YesOrNoQuestionsElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <FetchGPSElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <CameraElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <VideoElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <SignatureElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <DateTimeElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <SelfieCameraElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <TextAreaElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <DatePickerElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <DropdownElement 
                      component={component}
                      isRequired={isRequired}
                    />

                    <MovementOut 
                      component={component}
                      isRequired={isRequired}
                    />

                    <MovementTo 
                      component={component}
                      isRequired={isRequired}
                    />

                    <TwoDMovementOut 
                      component={component}
                      isRequired={isRequired}
                    />

                    <TwoDMovementTo 
                      component={component}
                      isRequired={isRequired}
                    />

                    <NFCScan 
                      component={component}
                      isRequired={isRequired}
                    />

                      <div className="component-icons">
                        <div className='editComponent' onClick={() => handleEditClick(component.type, component)}>
                          <span className='editIcon' >
                            <FaPencilAlt />
                          </span>
                        </div>
                        {!showSelectedForm ? (
                          <div className='deleteComponent' onClick={() => handleDeleteComponent(columnIndex, componentIndex)}>
                          <span className='deleteIcon' >
                            <FaTimes />
                        </span>
                        </div>
                        ) : (
                          <div className='deleteComponent' onClick={() => handleSelectedDeleteComponent(component.id2, columnIndex, componentIndex)}>
                          <span className='deleteIcon' >
                            <FaTimes />
                        </span>
                        </div>
                        )}
                        {/* <div className='deleteComponent' onClick={() => handleDeleteComponent(columnIndex, componentIndex)}>
                          <span className='deleteIcon' >
                            <FaTimes />
                        </span>
                        </div> */}
                        
                      </div>
                    
                        {showEditPanel && editedComponent && editedComponent.id === component.id && (
                          <EditModal
                            isOpen={showEditPanel}
                            onRequestClose={() => setShowEditPanel(false)}
                            elementName={editPanelData.elementName}
                            component={editPanelData.component}
                            onSave={handleEditModalSave}
                            onUpdate={handleEditModalUpdate}
                            selectedForm={selectedForm}
                            showSelectedForm={showSelectedForm}
                          />
                          // </div>
                        )}   

                        <SuccessToast 
                          message={message} 
                          isOpen={showSuccessToast} 
                          onToastClose={() => setShowSuccessToast(false)} 
                          onClose={() => onRequestClose()} 
                        />             
                    </div>
                  ))}
                </div>
                ))}
              </div>
            </div>
        </div>

          
        </div>
        <div className='bodyCol2'>
          <div className='descCol2'>
            <label className='desciptionLbl'>Drag and drop your elements below to the build area</label>               
          </div>
          <div className='elementsCol'>
            <DraggableComponents/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canvas;
