import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import './CreateDeviceModal.css';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import SuccessToast from '../../../SuccessToast/SuccessToast';

const CreateDeviceModal = ({ isOpen, onClose }) => {
    const headerData = useRecoilValue(AtomHeaderDataState)
    const companyWebValue = headerData[0].companyWeb;
    const [viewDevice, setViewDevice] = useState([]);
    const [viewSites, setViewSites] = useState([]);
    const [viewCompanyWeb, setCompanyWeb] = useState([]);
    const [selectedCoNo, setSelectedCoNo] = useState('');
    const [selectedSite, setSelectedSite] = useState('');
    const [selectedCompanyWeb, setSelectedCompanyWeb] = useState('');
    const [message, setMessage] = useState("");
    const [showSuccessToast, setShowSuccessToast] = useState(false);

    const [formData, setFormData] = useState({
        deviceId: '',
        deviceName: ''
        // Add other fields as needed
    });

    useEffect(() => {
        const fetchCompanyNos = async () => {
            try {
                const response = await axios.get(`https://myworxit.co.za/api/fetchCompanyNo.php`);
                const newData = response.data.records;
                setViewDevice(newData);
                console.log('newData------------: ', newData)
              } catch (error) {
                console.error("Error fetching data: ", error);
              }
        };
    
        fetchCompanyNos(); // Initial call
        const intervalId = setInterval(fetchCompanyNos, 5000);
        return () => clearInterval(intervalId);
      }, [setViewDevice]); // Empty dependency array to run this effect only once

      
      useEffect(() => {
        const fetchSites = async () => {
            try {
                if (selectedCompanyWeb) {
                    const response = await axios.get(`https://myworxit.co.za/api/${selectedCompanyWeb}/view/viewSites.php`);
                    const newData = response.data.records;
                    setViewSites(newData);
                    console.log('New Data cc000:', newData);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
    
        fetchSites(); // Initial call
        const intervalId = setInterval(fetchSites, 5000);
        return () => clearInterval(intervalId);
    }, [selectedCompanyWeb]); // Run this effect whenever selectedCompanyWeb changes

  
    
      useEffect(() => {
        const fetchCompanyWeb = async () => {
          try {
            const response = await axios.get(`https://myworxit.co.za/api/fetchCompanyWeb.php`);
            const newData = response.data.records;
            
            const filteredCompanyWeb = newData.filter((data) => selectedCoNo === data.coNo);
            
            console.log('filteredCompanyWeb: ', filteredCompanyWeb)
            
            setCompanyWeb(filteredCompanyWeb);
            
            console.log('newData: ', newData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
      };
      
        fetchCompanyWeb(); // Initial call
        const intervalId = setInterval(fetchCompanyWeb, 5000);
        return () => clearInterval(intervalId);
      }, [setCompanyWeb, selectedCoNo]); // Empty dependency array to run this effect only once


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

     const handleSubmit = (e) => {
        e.preventDefault();
      
        const sendData = {
          deviceId: formData.deviceId,
          deviceName: formData.deviceName,
          coNo: selectedCoNo,
          site: selectedSite,
          companyWeb: selectedCompanyWeb,
        };
      
        console.log(sendData);
      
        axios.post(`https://myworxit.co.za/api/createDevices.php`, sendData)
          .then((res) => {
            if (res.data.success) {
              setMessage(res.data.message);
              setShowSuccessToast(true);
            } else {
              setMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message); // Assuming the error message is meaningful
          });
      };
      

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-device-modal">
      <div className="create-device-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-device-title">Create Device</h3>
          </div>
          {headerData[0]?.role === 0 || headerData[0]?.role === 1 ? (
             <div className='defaultBtnCont'>
              <span onClick={handleSubmit} className='defaultBtn' type="submit">Add Device</span>
             </div>
            ) : null
          }
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
            <div className='createDeviceDeviceIdCont'>
              <div className='createDeviceDeviceIdTitle'>
                <label className='searchName'>
              Device ID:
              </label>
              </div>
              <div className='createDeviceDeviceId'>
                <input
                className='createDeviceDeviceIdInput'
                type="text"
                name="deviceId"
                value={formData.deviceId}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createDeviceDeviceIdCont'>
              <div className='createDeviceDeviceIdTitle'>
                <label className='searchName'>
              Device Name:
              </label>
              </div>
              <div className='createDeviceDeviceId'>
                <input
                className='createDeviceDeviceIdInput'
                type="text"
                name="deviceName"
                value={formData.deviceName}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createDeviceSelectedCoNoCont'>
              <div className='createDeviceCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Company No:</label>
              </div>
              <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateDeviceCompanyNoDropdown"
                  value={selectedCoNo}
                  onChange={(e) => setSelectedCoNo(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewDevice.map((item) => (
                    <option key={item.id} value={item.coNo}>
                      {item.coNo}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          
            <div className='createDeviceSelectedCoNoCont'>
              <div className='createDeviceCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Company Web:</label>
              </div>
              <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateDeviceCompanyNoDropdown"
                  value={selectedCompanyWeb}
                  onChange={(e) => setSelectedCompanyWeb(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewCompanyWeb.map((item) => (
                    <option key={item.id} value={item.companyWeb}>
                      {item.companyWeb}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className='createDeviceSelectedCoNoCont'>
              <div className='createDeviceCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Site:</label>
              </div>
              <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateDeviceCompanyNoDropdown"
                  value={selectedSite}
                  onChange={(e) => {
                    setSelectedSite(e.target.value);
                    }}
                >
                  <option value="">-- Select --</option>
                  {viewSites.map((item) => (
                    <option key={item.id} value={item.site}>
                      {item.site}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
             <SuccessToast 
                  message={message} 
                  isOpen={showSuccessToast} 
                  onToastClose={() => setShowSuccessToast(false)} 
                  onClose={() => onClose()} 
                />
        
      </div>
    </div>
  );
};

export default CreateDeviceModal;
