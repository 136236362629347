// UserModal.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import axios from 'axios';
import { FaTimes , FaPencilAlt} from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader'
import CreateDeviceModal from '../CreateDeviceModal/CreateDeviceModal';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { AtomRolesState } from '../../../../RecoilState/AtomRolesState';
import { useRecoilValue } from 'recoil';
import EditDeviceModal from '../EditDeviceModal/EditDeviceModal';
import DeleteDeviceModal from '../DeleteDeviceModal/DeleteDeviceModal';
import './DevicesModal.css';


const DevicesModal = ({ isOpen, onClose }) => {
  
  const [viewDevices, setViewDevices]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isCreateDeviceModalOpen, setCreateDeviceModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const headerData = useRecoilValue(AtomHeaderDataState);
  const userCompanyWeb = headerData[0].companyWeb;
  const roleLevel = useRecoilValue(AtomRolesState);
  const userRoleValue = headerData[0].role;
  const roleValue = roleLevel.role[userRoleValue];
  const [isEditDeviceModalOpen, setIsEditDeviceModalOpen] = useState(false) 
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [isDeleteDeviceModalOpen, setDeleteDeviceModalOpen] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(null);

  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [sortConfig, setSortConfig] = useState({ column: null, direction: 'ascending' });

  const devicesTable = useCallback(async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/viewDevices.php`);
      const newData = response.data.records;
      const filteredData = newData.filter((devices) => devices.companyWeb === userCompanyWeb);
      if (userCompanyWeb === 'worxit') {
        setViewDevices(prevDevices => newData); // Using functional form of setViewDevices
        // console.log('viewDevices: ', newData);
      } else {
        setViewDevices(prevDevices => filteredData); // Using functional form of setViewDevices
        // console.log('filteredData: ', filteredData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  }, [userCompanyWeb, setViewDevices]); // Include setViewDevices in the dependency array
  
  useEffect(() => {
    devicesTable(); // Initial call
    const intervalId = setInterval(devicesTable, 3000);
    return () => clearInterval(intervalId);
  }, [devicesTable]);
  
  // Ensure setIsLoading is imported and declared properly
  
  
  const filteredSearch = viewDevices.filter((user) => {
    if (searchColumn !== "") {
      return user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase());
    }
    // If "All" is selected, check if any column contains the search keyword
    return Object.values(user).some((value) =>
      value.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  });
    
  useEffect(() => {
    if (filteredSearch.length === 0 && searchKeyword !== "") {
      setMessage("No Match Found");
    } else {
      setMessage("");
    }
  }, [filteredSearch, searchColumn, searchKeyword]);


  const editForm = (device) => {
    setSelectedDevice(device);
    setIsEditDeviceModalOpen(true);
  };

  const deleteForm = (id) => {
    setDeviceToDelete(id);
    setDeleteDeviceModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://myworxit.co.za/api/deleteDevice.php?id=${deviceToDelete}`);
      setDeleteDeviceModalOpen(false);
    } catch (error) {
      console.error("Error deleting form: ", error);
    }
  };

  const cancelDelete = () => {
    setDeviceToDelete(null);
    setDeleteDeviceModalOpen(false);
  };

  const handleModalClose = () => {
    // Reset pagination and refetch data when modal is closed
    setPageNumber(0);
    devicesTable();
    onClose();
  };  

  

   /*-----------PAGGINATION-------------*/
   const [pageNumber, setPageNumber] = useState(0);
   const reportsPerPage = 5; // Set the number of reports to display per page
   const pagesVisited = pageNumber * reportsPerPage;
   const pageCount = Math.ceil(filteredSearch.length / reportsPerPage);
 
   const changePage = ({ selected }) => {
     setPageNumber(selected);
   };

   // Function to handle sorting
const handleSort = (column) => {
  // Toggle sorting order
  setIsSortingAsc((prev) => !prev);
  let direction = isSortingAsc ? 'ascending' : 'descending';
  setSortConfig({ column, direction });
};


// Function to sort data based on column and direction
const sortedData = useMemo(() => {
  let sortedUsers = [...filteredSearch];
  if (sortConfig.column !== null) {
    sortedUsers.sort((a, b) => {
     
      if (a[sortConfig.column] > b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      if (a[sortConfig.column] < b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      return 0;
    });
  }
  return sortedUsers;
}, [filteredSearch, sortConfig]);


  if (!isOpen) {
    return null;
  }

  return (
    <div className="devices-modal">
      <div className="devices-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='devices-title'>Devices</h3>
          </div>
          {roleValue.deviceAddBtn === '1' ? (
            <div className='defaultBtnCont'>
              <span className='defaultBtn' onClick={() => setCreateDeviceModalOpen(true)}>Add Device</span>
            </div>
            ) : null
          }
          <div onClick={handleModalClose} className='closeContAllModals' >
            <span className='closeAllModalIcon'>
            <FaTimes />
          </span>
        </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="deviceId">Device ID</option>
              <option value="deviceName">Device Name</option>
              <option value="coNo">Company No</option>
              <option value="site">Site</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
{/*          
        <th>Device Id</th>
                    <th>Device Name</th>
                    <th>Company No</th>
                    <th>Site</th>
                    <th>Status</th>
                    <th>Action</th> */}
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
          <thead>
            <tr className={`filterSortColumns ${isSortingAsc ? 'open' : ''}`}>
              <th onClick={() => handleSort('deviceId')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Device ID
                  {sortConfig.column === 'deviceId' && (
                    <div className="arrow"></div>
                    )}
                </div>
              </th>
              <th onClick={() => handleSort('deviceName')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Device Name
                  {sortConfig.column === 'deviceName' && (
                    <div className="arrow"></div>
                    )}
                </div>
              </th>
              <th onClick={() => handleSort('coNo')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Company No
                    {sortConfig.column === 'coNo' && (
                      <div className="arrow"></div>
                    )}
                </div>
              </th>
              <th onClick={() => handleSort('site')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Site
                  {sortConfig.column === 'site' && (
                    <div className="arrow"></div>
                  )}
                </div>
              </th>
              <th>Status</th>
              <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : sortedData.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  sortedData
                  .slice(pagesVisited, pagesVisited + reportsPerPage)
                    .map((viewDevice, index) => (
                      <tr key={index}>
                        <td>{viewDevice.deviceId}</td>
                        <td>{viewDevice.deviceName}</td>
                        <td>{viewDevice.coNo}</td>
                        <td>{viewDevice.site}</td>
                        <td>
                          <div
                            className={`statusCircle ${
                              viewDevice.status === '1' ? 'green' : 'red'
                            }`}
                          ></div>
                        </td>
                        <td>
                        <div className='editDeleteIcons'>
                        {/* <div className='viewRow'>
                           <span title='View Site' className='viewRowIcon' >
                           <HiViewList />
                          </span>
                        </div> */}
                        <div className='editRow'>
                           <span title='Edit Site' className='editRowIcon' onClick={() => editForm(viewDevice)}>
                            <FaPencilAlt />
                          </span>
                        </div>
                        <div className='deleteRow'>
                          <span title='Delete Site' className='deleteRowIcon' onClick={() => deleteForm(viewDevice.id)}>
                            <FaTimes />
                          </span>
                        </div>
                        </div>                        
                      </td>
                    </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              {/* <label className="lblRowCo">Rows per page:</label> */}
              {/* <select className="pageRowsCo" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select> */}
            

            <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>} 
        
        {isCreateDeviceModalOpen && (
          <CreateDeviceModal 
            isOpen={isCreateDeviceModalOpen} 
            onClose={() => setCreateDeviceModalOpen(false)} />
        )}

        {selectedDevice && (
          <EditDeviceModal
            isOpen={isEditDeviceModalOpen}
            onClose={() => setIsEditDeviceModalOpen(false)}
            device={selectedDevice}
          />
        )}

        <DeleteDeviceModal
          isOpen={isDeleteDeviceModalOpen}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      </div>
    </div>
  );
};

export default DevicesModal;
