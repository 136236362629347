// EditCompanyModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import Loader from '../../../../Loader/Loader';
import axios from 'axios';
import './EditCompanyModal.css'

const EditCompanyModal = ({ isOpen, onClose, company }) => {
  const [editableField, setEditableField] = useState(null);
  const [editedCompany, setEditCompany] = useState({ ...company });
  const [message, setMessage] = useState("");
  const [isSuspended, setIsSuspended] = useState(false);

  const handleEditClick = (fieldName) => {
    setEditableField(fieldName);
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
  
    const sendData={
      id: company.id,
      coNo: editedCompany.coNo,
      coName: editedCompany.coName,
      coReg: editedCompany.coReg,
      coContact: editedCompany.coContact,
      coAdd: editedCompany.coAdd  
     }
     console.log(sendData)
     axios.post(`https://myworxit.co.za/api/updateCompany.php`,sendData)
     .then((res)=> {
       if (res.data.success) {
         onClose();  // Close the modal only if the response is true
       } else {
         setMessage(res.data.message);
       }
       // setMessageSuc(res.data.messageSuc);
       // setMessageError("");
       // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
     })
     .catch((res) => {
      console.error("Error updating site:", res);
      setMessage("An error occurred during the update");
      
     });
  }

  const handleSuspendClick = () => {
    // Toggle the isSuspended state
    setIsSuspended((prevIsSuspended) => !prevIsSuspended);

    // Send the suspension request immediately
    const sendData = {
      id: company.id,
      status: isSuspended ? '1' : '0', // Set the status based on isSuspended state
    };
    console.log(sendData)
    axios.post(`https://myworxit.co.za/api/updateStatus.php`, sendData)
      .then((res) => {
        if (res.data.success) {
          onClose();  // Close the modal only if the response is true
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((res) => {
        console.error("Error updating site:", res);
        setMessage("An error occurred during the update");
      });
  };
  

  const handleUpdateClick = () => {
     // After updating, switch back to view mode
    setEditableField(null);
  };

  const handleInputChange = (e) => {
    // Update the editedSite state when input values change
    const { name, value } = e.target;
    setEditCompany((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update editedSite when the site prop changes
    setEditCompany({ ...company });
  }, [company]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <h3 className='SitesZones-title'>Company Details</h3>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company No:</span>
        </div>
          
          {editableField === 'coNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="coNo"
                value={editedCompany.coNo}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedCompany.coNo}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coNo')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Name:</span>
        </div>
          
          {editableField === 'coName' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="coName"
                value={editedCompany.coName}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedCompany.coName}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coName')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Reg:</span>
        </div>
          
          {editableField === 'coReg' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="coReg"
                value={editedCompany.coReg}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedCompany.coReg}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coReg')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Contact:</span>
        </div>
          
          {editableField === 'coContact' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="coContact"
                value={editedCompany.coContact}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedCompany.coContact}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coContact')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Address:</span>
        </div>
          
          {editableField === 'coAdd' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="coAdd"
                value={editedCompany.coAdd}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedCompany.coAdd}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coAdd')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Status:</span>
        </div>
          
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span> 
                  <div
                    className={`statusCircle ${
                    editedCompany.status === '1' ? 'green' : 'red'
                    }`}
                  ></div>
                </span>
              </div>
            </div>
        </div>
       
        <div onClick={onClose} className='closeContUserModal' >
          <span className='closeUserModalIcon'>
            <FaTimes />
          </span>
        </div>
        <div className='companyDetailsBtnsCont'>
          <div className='saveCompanyDetailsCont'>
            <span className='saveCompanyDetailsBtn' onClick={handleUpdateSubmit}>Save</span>
          </div>
          <div className='deleteCompanyCont'>
            <span className='deleteCompanyBtn' onClick={handleUpdateSubmit}>Delete</span>
          </div>
          <div className='suspendCompanyCont'>
            {editedCompany.status === '1' ? (
              <span className='suspendCompanyBtn' onClick={handleSuspendClick}>Suspend</span>
            ) :(
              <span className='suspendCompanyBtn' onClick={handleSuspendClick}>Activate</span>
            )
            }
            
          </div>
        </div>
          
      </div>
    </div>
  );
};

export default EditCompanyModal;
