// UserModal.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
// import ViewUserSitesModal from './ViewUserSitesModal'
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState'
import './ViewUserSitesModal.css';
import { useRecoilValue } from 'recoil';
import Checkbox from '@mui/material/Checkbox';
import ViewUserZonesModal from '../ViewUserZonesModal/ViewUserZonesModal';

const ViewUserSitesModal = ({ isOpen, onClose, user }) => {
  
  const [viewSites, setViewSites]= useState([]); //change this to an atom instead
  const [isLoading, setIsLoading] = useState(false);
  const [editedSite, setEditedUserDetails] = useState({ ...user });
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isViewUserZonesModalOpen, setIsViewUSerZonesModalOpen] = useState(false);
  const roleValue = useRecoilValue(AtomHeaderDataState)
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [selectedSite, setSelectedSite] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);
  const [checkedSites, setCheckedSites] = useState([]);


  const SitesTable = async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewSites.php`);
      const newData = response.data.records;
      setViewSites(newData);
      setIsLoading(false);
      console.log('New Data cc000:', newData);
      
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
    
useEffect(() => {
    SitesTable(); // Initial call
    const intervalId = setInterval(SitesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewSites]); // Empty dependency array to run this effect only once
  
  
  const filteredSearch = viewSites.filter((user) => {
    // Check if the user matches the search criteria
    const matchesSearch =
      searchColumn !== ""
        ? user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase())
        : Object.values(user).some((value) =>
            value.toLowerCase().includes(searchKeyword.toLowerCase())
          );
  
    // // Check if the user matches the role criteria based on the logged-in user's role
    // const matchesRole =
    //   roleValue[0]?.role === 3 ? user.role === '3' : true;
  
      console.log('user:', user);
  console.log('matchesSearch:', matchesSearch);
  //console.log('matchesRole:', matchesRole);
  
    // Combine the search and role criteria
    return matchesSearch 
    //&& matchesRole;
  });

  console.log('filteredSearch', filteredSearch)

  const viewZones = (site) =>{
    setSelectedSite(site);
    setIsViewUSerZonesModalOpen(true)
    console.log('site: ', site)
  }

  const handleCheckboxChange = (site, isChecked) => {
    setCheckedSites((prevCheckedSites) => {
      if (isChecked) {
        // If the checkbox is checked, add the site to the array only if it's not already present
        return [...new Set([...prevCheckedSites, site])];
        
      } else {
        // If the checkbox is unchecked, remove the site from the array
        return prevCheckedSites.filter((checkedSite) => checkedSite !== site);
      }
    });
  console.log('checkedSites: ',checkedSites )
    // Update the editedSite state if needed
    setEditedUserDetails((prevEditedSite) => {
      const existingSites = prevEditedSite.site ? JSON.parse(prevEditedSite.site) : [];
  
      if (isChecked) {
        // If the checkbox is checked, add the site to the array
        const updatedSites = [...new Set([...existingSites, site])];
        return { ...prevEditedSite, site: JSON.stringify(updatedSites) };
      } else {
        // If the checkbox is unchecked, remove the site from the array
        const updatedSites = existingSites.filter((existingSite) => existingSite !== site);
        return { ...prevEditedSite, site: JSON.stringify(updatedSites) };
      }
    });
    console.log('editedSite: ',editedSite )
  };
  
  
  
  
  
  console.log('editedSite: ', editedSite)
  
  
  const handleUpdate=(e ,site)=>{
    e.preventDefault();

    // if (data.password !== data.confirmPassword) {
    //   setMessage("Passwords do not match.");
    // return;
    // }
    const updatedSites = [...new Set((editedSite.site ? JSON.parse(editedSite.site) : []).concat(checkedSites))];

   const sendData={
      id: user.id,
      site: updatedSites

    }
    console.log(sendData)
    axios.post(`https://myworxit.co.za/api/updateUsersSites.php`,sendData)
    .then((res)=> {
      if (res.data.success) {
        onClose();  // Close the modal only if the response is true
      } else {
        //setMessage(res.data.message);
      }
      // setMessageSuc(res.data.messageSuc);
      // setMessageError("");
      // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
    })
    .catch((res) => {
      console.error(res);
     
    });
  }
  
  // const handleUpdate = async () => {
  //   try {
  
  //     console.log('Updating sites:', checkedSites);
  //   } catch (error) {
  //     console.error('Error updating data:', error);
  //   }
  // };

  
  

  if (!isOpen) {
    return null;
  }

  return (
    <div className="viewUserSites-modal">
      <div className="viewUserSites-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='viewUserSites-title'>Sites</h3>
          </div>
          {roleValue[0]?.role === 0 || roleValue[0]?.role === 1 || roleValue[0]?.role === 2 || roleValue[0]?.role === 3 ? (
            <div className='defaultBtnCont'> 
            <span className='defaultBtn' onClick={handleUpdate}>Update</span>
            </div>
            ) : null
          }
          <div onClick={onClose} className='closeContAllModals' >
              <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="site">Sites</option>
              <option value="siteAddress">Site Address</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Sites</th>
                    <th>Site Address</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : filteredSearch.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  filteredSearch
                    .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                    .map((viewSites, index) => (
                      <tr key={index}>
                        <td>{viewSites.site}</td>
                        <td>{viewSites.siteAddress}</td>
                        <td>
                          <div className='actionCont'>
                            <div className='checkBoxCont'>
                            <Checkbox
                            //checked={checkedSites.includes(viewSites.site)} 
                            checked={
                              editedSite.site
                                ? JSON.parse(editedSite.site).includes(viewSites.site)
                                : false
                            }
                            onChange={(e) => handleCheckboxChange(viewSites.site, e.target.checked)}
                           
                          />


                            </div>
                            <div className='siteBtnCont'>
                              <span className='defaultBtn' onClick={() => viewZones(viewSites)}>Zones</span>
                            </div>
                            
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
                onPageChange={({ selected }) => setPageNumber(selected)}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
            
          {selectedSite && (
          <ViewUserZonesModal 
            isOpen={isViewUserZonesModalOpen} 
            onClose={() => setIsViewUSerZonesModalOpen(false)} 
            site={selectedSite}
            user={user}/>
            
        )}
      
      </div>
    </div>
  );
};

export default ViewUserSitesModal;
