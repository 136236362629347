import React, { useState } from 'react';
import DraggableComponents from './DraggableComponent';
import Canvas from './Canvas';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './FormBuilder.css';

function FormBuilder({selectedForm, columns, setColumns, showSelectedForm,onRequestClose, activeTabIndex, isAccessControlChecked, setIsAccessControlChecked}) {
  const [formConfig, setFormConfig] = useState([]);

  function generateRan2Let2Num() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let formId = '';
  
    // Generate two random letters
    for (let i = 0; i < 2; i++) {
      formId += letters.charAt(Math.floor(Math.random() * letters.length));
    }
  
    // Generate two random numbers
    for (let i = 0; i < 2; i++) {
      formId += Math.floor(0 + Math.random() * 9);
    }
  
    return formId;
  }

  
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="form-builder">
        <Canvas 
          formConfig={formConfig} 
          setFormConfig={setFormConfig} 
          generateRan2Let2Num={generateRan2Let2Num}
          selectedForm={selectedForm}
          columns={columns}
          setColumns={setColumns}
          showSelectedForm={showSelectedForm}
          onRequestClose={onRequestClose}
          activeTabIndex={activeTabIndex}
          isAccessControlChecked={isAccessControlChecked}
          setIsAccessControlChecked={setIsAccessControlChecked}
        />
        {/* <div className='formBuilerElements'>
          <div className='descriptionElementsCont'>
            <label className='desciptionLbl'>Drag and drop your elements below to the build area</label>
          </div>
          <DraggableComponents />
        </div> */}
        
      </div>
    </DndProvider>
  );
}

export default FormBuilder;
