// UserModal.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
import CreateRolesModal from '../CreateRolesModal/CreateRolesModal';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState'
import './RolesModal.css';
import { useRecoilValue } from 'recoil';


const RolesModal = ({ isOpen, onClose }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [viewRoles, setViewRoles]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isCreateRolesModalOpen, setCreateRolesModalOpen] = useState(false);
  const roleValue = useRecoilValue(AtomHeaderDataState)

  // const [message, setMessage] = useState("");
    
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);

  const rolesTable = async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewRoles.php`);
      const newData = response.data.records;
      setViewRoles(newData);
      setIsLoading(false);
           
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
    
useEffect(() => {
    rolesTable(); // Initial call
    const intervalId = setInterval(rolesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewRoles]); // Empty dependency array to run this effect only once
  
 
  const filteredSearch = viewRoles.filter((role) => {
    // Check if the user matches the search criteria
    const matchesSearch =
      searchColumn !== ""
        ? role[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase())
        : Object.values(role).some((value) =>
            value.toLowerCase().includes(searchKeyword.toLowerCase())
          );
  
    // Check if the user matches the role criteria based on the logged-in user's role
    const matchesRole =
      roleValue[0]?.role === 3 ? role.role === '3' : true;
  
    // Combine the search and role criteria
    return matchesSearch && matchesRole;
  });
  
    
  
  //   const deleteUser = (id) =>{
  //     axios.delete(`https://myworxit.co.za/api/${crusaders}/delete/deleteUser.php`, {data: {id: id}})
  //     .then(()=>{
  //       rolesTable();
  //     }).catch(()=>{
  //         alert("Error, Cannot Delete User!");
  //     })
  // }

  if (!isOpen) {
    return null;
  }

  return (
    <div className="roles-modal">
      <div className="roles-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='roles-title'>Roles</h3>
          </div>
            {roleValue[0]?.role === 0 || roleValue[0]?.role === 1 || roleValue[0]?.role === 2 || roleValue[0]?.role === 3 ? (
              <div className='defaultBtnCont'> 
                <span className='defaultBtn' onClick={() => setCreateRolesModalOpen(true)}>Add Role</span>
              </div>
            ) : null
          }
          <div onClick={onClose} className='closeContAllModals' >
                <span className='closeAllModalIcon'>
                <FaTimes />
              </span>
          </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="username">Username</option>
              <option value="role">Roles</option>
              <option value="coNo">Company No</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
       
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Company No</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : filteredSearch.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  filteredSearch
                    .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                    .map((viewUsers, index) => (
                      <tr key={index}>
                        <td>{viewUsers.coNo}</td>
                        <td>{viewUsers.username}</td>
                        <td>{viewUsers.role}</td>
                        <td>
                          {/* <Link to={`/editAllUsers/${viewUsers.id}`}><EditIcon/>
                          </Link>  */}
                          {/* <button onClick={() => deleteCompany(viewUsers.id)}>< DeleteForeverIcon sx={{ color: red[900] }}/></button> */}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
                onPageChange={({ selected }) => setPageNumber(selected)}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
            
          {isCreateRolesModalOpen && (
          <CreateRolesModal 
            isOpen={isCreateRolesModalOpen} 
            onClose={() => setCreateRolesModalOpen(false)} />
        )}
      </div>
    </div>
  );
};

export default RolesModal;
