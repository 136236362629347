import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import './ViewUserDetailsModal.css';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { AtomRolesState } from '../../../../RecoilState/AtomRolesState';
import { useRecoilValue } from 'recoil';

const ViewUserDetailsModal = ({ isOpen, onClose, user }) => {
    const [viewSelectedUsers, setViewSelectedUser] = useState({ ...user });
      
    if (!isOpen) {
        return null;
    }

    return (
      <div className="create-user-details-modal">
        <div className="create-user-details-modal-content">
          <div className="modalHeader">
            <div className="header-title-cont"> 
              <h3 className="create-user-details-title">View User Details</h3>
            </div>
            <div onClick={onClose} className="closeContAllModals">
              <span className="closeAllModalIcon">
                <FaTimes />
              </span>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Company No:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.coNo}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Company Web:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.companyWeb}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Username:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.username}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Role:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.role}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Name:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.name}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Surname:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.userSurname}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Identity No:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.identityNo}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Employee No:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.empNo}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Contact No:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.userContact}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Email:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.userEmail}</span>
              </div>
            </div>
          </div>
          <div className="editable-field">
            <div className='editable-field-col1'>
              <span>Address:</span>
            </div>
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{viewSelectedUsers.userAddress}</span>
              </div>
            </div>
          </div>          
        </div>
      </div>
    );
  }    


export default ViewUserDetailsModal;
