import React, { useState } from 'react';
import '../Styles/MyStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEnvelope ,faUserLock,faCalendarDays, faClipboardList, faClipboardUser} from '@fortawesome/free-solid-svg-icons';
import { GoArrowSwitch } from "react-icons/go";

import AccessControlModal from '../Modals/SidebarModals/AccessControlModal/AccessControlModal';
import AssetsModal from '../Modals/SidebarModals/AssetsModal/AssetsModal';
import AuditsModal from '../Modals/SidebarModals/AuditsModal/AuditsModal';
import DocumentsModal from '../Modals/SidebarModals/DocumentsModal/DocumentsModal';
import EventsModal from '../Modals/SidebarModals/EventsModal/EventsModal';
import PatrolsModal from '../Modals/SidebarModals/PatrolsModal/PatrolsModal';
import TimeAttendanceModal from '../Modals/SidebarModals/TimeAttendanceModal/TimeAttendanceModal';
import ReportsModal from '../Modals/SidebarModals/ReportsModal/ReportsModal';
import WorkflowsModal from '../Modals/SidebarModals/WorkflowsModal/WorkflowsModal';
import MovementModal from '../Modals/SidebarModals/MovementModal/MovementModal';

const Sidebar = ({ isOpen, setIsSidebarOpen,closeBurgerMenu,isSettingsDropdownOpen, setIsSettingsDropdownOpen, isUserDropdownOpen, setIsUserDropdownOpen  }) => {
  
  
  const [isAccessControlModalOpen, setIsAccessControlModalOpen] = useState(false);
  const [isEventsModalOpen, setIsEventsModalOpen] = useState(false);
  const [isReportsModalOpen, setIsReportsModalOpen] = useState(false);
  const [isWorkflowsModalOpen, setIsWorkflowsModalOpen] = useState(false);
  const [isTimeAttenModalOpen, setIsTimeAttenModalOpen] = useState(false);
  const [isPatrolsModalOpen, setIsPatrolsModalOpen] = useState(false);
  const [isAssetsModalOpen, setIsAssetsModalOpen] = useState(false);
  const [isDocsModalOpen, setIsDocsModalOpen] = useState(false);
  const [isAuditsModalOpen, setIsAuditsModalOpen] = useState(false);
  const [isMovementModalOpen, setIsMovementModalOpen] = useState(false)

  //--------SIDEBAR MODALS START----------
  const openAccessControlModal = () => {
    setIsAccessControlModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openEventsModal = () => {
    setIsEventsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openReportsModal = () => {
    setIsReportsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openWorkflowsModal = () => {
    setIsWorkflowsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openTimeAttenModal = () => {
    setIsTimeAttenModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openPatrolsModal = () => {
    setIsPatrolsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openAssetsModal = () => {
    setIsAssetsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openDocsModal = () => {
    setIsDocsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openAuditsModal = () => {
    setIsAuditsModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };

  const openMovementModal = () => {
    setIsMovementModalOpen(true);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
  };
//-------------END--------------------

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <ul className="menu-list">
         <li onClick={openAccessControlModal} title='Access control'>
          <div className={`menu-icon`}>
            <FontAwesomeIcon icon={faUserLock} />
          </div>
            {isOpen && <span >Access Control</span>}
            
        </li>
        {/*<li onClick={openWorkflowsModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faClipboardList} />
          </div>
            {isOpen && <span >Workflows</span>}
        </li>
        <li onClick={openTimeAttenModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faClipboardUser} />
          </div>
            {isOpen && <span >Time & Attendance</span>}
        </li>
        <li onClick={openPatrolsModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
            {isOpen && <span >Patrols</span>}
        </li>
        <li onClick={openAssetsModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
            {isOpen && <span >Assets</span>}
        </li>
        <li onClick={openDocsModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
            {isOpen && <span >Documents</span>}
        </li>
        <li onClick={openAuditsModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
            {isOpen && <span>Audits</span>}
        </li>
        <li onClick={openEventsModal}>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`}>
            <FontAwesomeIcon icon={faCalendarDays} />
          </div>
            {isOpen && <span >Events</span>}
        </li> */}
        <li onClick={openReportsModal} title='Reports'>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`} >
            <FontAwesomeIcon icon={faClipboardList} />
          </div>
            {isOpen && <span>Reports</span>}
        </li>
        <li onClick={openMovementModal} title='Movement'>
          <div className={`menu-icon ${!isOpen ? 'show' : ''}`} >
            <GoArrowSwitch  />
          </div>
            {isOpen && <span>Movement</span>}
        </li>
      </ul>

          <AccessControlModal
            isOpen={isAccessControlModalOpen}
            onClose={() => setIsAccessControlModalOpen(false)}
          />

          <EventsModal
            isOpen={isEventsModalOpen}
            onClose={() => setIsEventsModalOpen(false)}
          />

          <ReportsModal
            isOpen={isReportsModalOpen}
            onClose={() => setIsReportsModalOpen(false)}
          />

          <WorkflowsModal
            isOpen={isWorkflowsModalOpen}
            onClose={() => setIsWorkflowsModalOpen(false)}
          />
          
          <TimeAttendanceModal
            isOpen={isTimeAttenModalOpen}
            onClose={() => setIsTimeAttenModalOpen(false)}
          />

          <PatrolsModal
            isOpen={isPatrolsModalOpen}
            onClose={() => setIsPatrolsModalOpen(false)}
          />

          <AssetsModal
            isOpen={isAssetsModalOpen}
            onClose={() => setIsAssetsModalOpen(false)}
          />

          <DocumentsModal
            isOpen={isDocsModalOpen}
            onClose={() => setIsDocsModalOpen(false)}
          />

          <AuditsModal
            isOpen={isAuditsModalOpen}
            onClose={() => setIsAuditsModalOpen(false)}
          />

          <MovementModal
            isOpen={isMovementModalOpen}
            onClose={() => setIsMovementModalOpen(false)}
          />
    </div>
  );
};


export default Sidebar;
