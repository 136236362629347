import React, { useState } from 'react';
import axios from 'axios';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import SuccessToast from '../../../SuccessToast/SuccessToast';
import DeletefreqVisitorsModal from '../DeletefreqVisitorsModal/DeletefreqVisitorsModal'
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState';
import {AtomFreqCategoryDataState} from '../../../../RecoilState/AtomFreqCategoryDataState';
import { useRecoilValue } from 'recoil';
import './AddCategoriesModal.css';

const AddCategoriesModal = ({ isOpen, onClose }) => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const [viewFreqCategories, setViewFreqCategories] = useRecoilState(AtomFreqCategoryDataState);
    const viewFreqCategories = useRecoilValue(AtomFreqCategoryDataState)
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const headerData = useRecoilValue(AtomHeaderDataState)
    const companyWebValue = headerData[0].companyWeb;
    const [isDeleteSiteModalOpen, setDeleteSiteModalOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [categoryData, setCategoryData] = useState({
      category: '',
      id: null, // Add id state
      isEditing: false, // Track if editing is enabled
      editedCategory: '', // Track the edited category
        // Add other fields as needed
    });

    console.log('viewFreqCategories-----', viewFreqCategories)

    const handleChange = (e) => {
      const { name, value } = e.target;
      setCategoryData({
        ...categoryData,
        [name]: value,
      });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        const sendData = {
          category: categoryData.category,
          
        };
      
        console.log(sendData);
      
        axios.post(`https://myworxit.co.za/api/${companyWebValue}/create/createFreqCategories.php`, sendData)
          .then((res) => {
            if (res.data.success) {
              setShowSuccessToast(true);
            } else {
              setMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message); // Assuming the error message is meaningful
          });
      };

      const handleEdit = (category, id) => {
        setCategoryData({
            ...categoryData,
            isEditing: true,
            editedCategory: category,
            id: id, // Set id state
        });
    };

    const handleSave = (e) => {        
       
      
        setCategoryData({
          ...categoryData,
            isEditing: false,
            id: null,
        });

        const sendData = {
          id: categoryData.id,
          category: categoryData.editedCategory,
          
        };
      
        console.log('sendData------', sendData);
      
        axios.post(`https://myworxit.co.za/api/${companyWebValue}/update/updateFreqCategories.php`, sendData)
          .then((res) => {
            if (res.data.success) {
              setShowSuccessToast(true);
            } else {
              setMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message); // Assuming the error message is meaningful
          });
      };

      const deleteForm = (id) => {
        setCategoryToDelete(id);
        setDeleteSiteModalOpen(true);
      };
    
      const confirmDelete = async () => {
        try {
            await axios.delete(`https://myworxit.co.za/api/${companyWebValue}/delete/deleteFreqCategories.php?id=${categoryToDelete}`);
            setDeleteSiteModalOpen(false);
         
        } catch (error) {
          console.error("Error deleting form: ", error);
        }
      };
    
      const cancelDelete = () => {
        setCategoryToDelete(null);
        setDeleteSiteModalOpen(false);
      };
     
    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-company-modal">
            <div className="create-company-modal-content">
                <div className='modalHeader'>
                    <div className='header-title-cont'>
                        <h3 className="create-company-title">Add Categories</h3>
                    </div>
                    <div onClick={onClose} className="closeContAllModals">
                        <span className="closeAllModalIcon">
                            <FaTimes />
                        </span>
                    </div>
                </div>
                <div className='createCategoryCont'>
                    <div className='createCategoryTitle'>
                        <label className='searchName'>Category:</label>
                    </div>
                    <div className='createCategory'>
                        
                            <input
                                className='createCategoryInput'
                                type="text"
                                name="category"
                                value={categoryData.category}
                                onChange={handleChange}
                            />
                        
                    </div>
                    <div className='defaultBtnCont'>
                        <span onClick={handleSubmit} className='defaultBtn' type="button">Add</span>
                    </div>
                </div>
                <div className='categoriesTitleCont'>
                    <label className='categoriesTitle'>Categories</label>
                </div>
                {isLoading ? (
                    <label>Loading...</label>
                ) : viewFreqCategories.length > 0 ? (
                    viewFreqCategories.map((viewSites, index) => (
                        <div className='categoriesDataListCont' key={index}>
                            <div className='categoriesDataList'>
                                {categoryData.isEditing && viewSites.id === categoryData.id ? (
                                    <input
                                        className='editInputCategory'
                                        type="text"
                                        name="editedCategory"
                                        value={categoryData.editedCategory}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    <span>{viewSites.category}</span>
                                )}
                            </div>
                            <div className='actionBtnsCont'>
                              {categoryData.isEditing && viewSites.id === categoryData.id ? (
                                  <span onClick={handleSave} className="saveCategoryIcon">
                                      <MdSaveAlt />
                                  </span>
                                ) : (
                                  <span onClick={() => handleEdit(viewSites.category, viewSites.id)} className="editCategoryIcon">
                                      <FaPencilAlt />
                                  </span>
                                )}
                                <span className="deleteCategoryIcon">
                                    <FaTimes onClick={() => deleteForm(viewSites.id)}/>
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No Records Found</div>
                )}
                <div className='messagesCont'>
                    {message && <p className='error-message'>{message}</p>}
                </div>
                <SuccessToast
                    message={message}
                    isOpen={showSuccessToast}
                    onToastClose={() => setShowSuccessToast(false)}
                    onClose={() => onClose()}
                />

                 <DeletefreqVisitorsModal
                    isOpen={isDeleteSiteModalOpen}
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}/>

            </div>
        </div>
    );
};


export default AddCategoriesModal;
