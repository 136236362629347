
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import { FaRegBuilding } from "react-icons/fa";
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { AtomHeaderDataState } from '../RecoilState/AtomHeaderDataState';
import locationMarker from '../Media/locationMarker.png'

//import 'leaflet-geosearch/dist/geosearch.css';

const MapView = () => {
    const [viewSites, setViewSites]= useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const headerData = useRecoilValue(AtomHeaderDataState)
    const companyWebValue = headerData[0].companyWeb;
    
    const SitesTable = useCallback(async () => {
        try {
          const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewSiteCoordinates.php`);
          const newData = response.data.records;
          console.log('newData-------- ', newData)
          setViewSites(newData);
          setIsLoading(false);
        
        } catch (error) {
          setIsLoading(false);
        }
      }, [companyWebValue, setViewSites]);
        
    useEffect(() => {
        SitesTable(); // Initial call
        const intervalId = setInterval(SitesTable, 3000);
        return () => clearInterval(intervalId);
      }, [setViewSites, SitesTable]); // Empty dependency array to run this effect only once
    
//       const geocodeAddress = useCallback(async (address) => {
//         try {
//             const response = await axios.get(`https://nominatim.openstreetmap.org/search.php?format=json&limit=1&q=${encodeURIComponent(address)}`);
//             if (response.data && response.data.length > 0) {
//                 return {
//                     lat: parseFloat(response.data[0].lat),
//                     lon: parseFloat(response.data[0].lon)
//                 };
//             }
//             return null;
//         } catch (error) {
//             console.error('Error geocoding address:', error);
//             return null;
//         }
//     }, []);
    
    

//     useEffect(() => {
//       const fetchCoordinates = async () => {
//           try {
//               const promises = viewSites.map(async (site) => {
//                   const { siteAddress } = site;
//                   return await geocodeAddress(siteAddress);
//               });
//               const coordinates = await Promise.all(promises);
//               // Filter out any null coordinates
//               const validCoordinates = coordinates.filter(coord => coord !== null);
//               console.log('Coordinates:', coordinates);
//               console.log('Valid Coordinates:', validCoordinates);
//               // Update state with valid coordinates
//               setViewSites(prevSites => prevSites.map((site, index) => {
//                   return {
//                       ...site,
//                       coordinates: validCoordinates[index]
//                   };
//               }));
//           } catch (error) {
//               console.error('Error fetching coordinates:', error);
//           }
//       };
      
//       fetchCoordinates();
//   }, [viewSites, geocodeAddress]);
  

    const lat = "-30"
    const long = "31"
    const position = [lat, long]

        const buildingIcon = new Icon({
        iconUrl: locationMarker,
        iconSize: [30, 30] // Set the icon size as per your requirement
    });
   
    return (
        <div className="MainContainer">
            <MapContainer 
                center={position}
                zoom={8} 
                style={{ height: '100vh', width: '100%' }} 
                crollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
               {viewSites.map(site => (
                        site.coordinates && (
                            <Marker key={site.id} position={[site.coordinates.lat, site.coordinates.lon]} icon={buildingIcon}>
                                <Popup>{site.siteAddress}</Popup>
                            </Marker>
                        )
                    ))}
            </MapContainer>
        </div>
        
    );
}

export default MapView;