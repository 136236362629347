// UserModal.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { AtomFormsDataState } from '../../../../RecoilState/AtomFormsDataState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

const ViewReportsForms = ({selectedFormClick, filteredData, selectedForm}) => {
    const headerData = useRecoilValue(AtomHeaderDataState)
    const companyWebValue = headerData[0].companyWeb;
    const setFormsCreated = useSetRecoilState(AtomFormsDataState);
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [formToDelete, setFormToDelete] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const reportsPerPage = 5; // Set the number of reports to display per page
    const pagesVisited = pageNumber * reportsPerPage;
    const [isSortingAsc, setIsSortingAsc] = useState(true);
    const [sortConfig, setSortConfig] = useState({ column: null, direction: 'ascending' });

    const viewCreatedForms = useCallback(async () => {
        try {
          const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewForms.php`);
          const newData = response.data.records;
          setFormsCreated(newData);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }, [companyWebValue, setFormsCreated]);
      
    useEffect(() => {
      viewCreatedForms(); // Initial call
      const intervalId = setInterval(viewCreatedForms, 3000);
      return () => clearInterval(intervalId);
    }, [setFormsCreated, viewCreatedForms]); // Empty dependency array to run this effect only once

      const pageCount = Math.ceil(filteredData.length / reportsPerPage);

      const changePage = ({ selected }) => {
        setPageNumber(selected);
      };

      const deleteForm = (formId) => {
        setFormToDelete(formId);
        setDeleteConfirmationOpen(true);
      };
    
      const confirmDelete = async () => {
        try {
          await axios.delete(`https://myworxit.co.za/api/${companyWebValue}/delete/deleteForm.php?formId=${formToDelete}`);
          setDeleteConfirmationOpen(false);
         
        } catch (error) {
          console.error("Error deleting form: ", error);
        }
      };
    
      const cancelDelete = () => {
        setFormToDelete(null);
        setDeleteConfirmationOpen(false);
      };
                 // Function to handle sorting
    const handleSort = (column) => {
    // Toggle sorting order
    setIsSortingAsc((prev) => !prev);
    let direction = isSortingAsc ? 'ascending' : 'descending';
    setSortConfig({ column, direction });
    };
  
  
  // Function to sort data based on column and direction
  const sortedData = useMemo(() => {
    let sortedUsers = [...filteredData];
    if (sortConfig.column !== null) {
      sortedUsers.sort((a, b) => {
       
        if (a[sortConfig.column] > b[sortConfig.column]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        if (a[sortConfig.column] < b[sortConfig.column]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        return 0;
      });
    }
    return sortedUsers;
  }, [filteredData, sortConfig]);
    

    return(
        <div>
            <table className='formBuilderTable'>
                <thead>
                  <tr className={`filterSortColumns ${isSortingAsc ? 'open' : ''}`}>
                    <th onClick={() => handleSort('fieldName')} className="filterSortColumns-toggle">
                        <div className='filterSortColumnsHeader'>
                            Form Names
                            {sortConfig.column === 'fieldName' && (
                            <div className="arrow"></div>
                            )}
                        </div>
                        </th>    
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                  {sortedData
                    .slice(pagesVisited, pagesVisited + reportsPerPage)
                    .map(form => (
                      <tr key={form.id2}>
                        <td>{form.fieldName}</td>
                        <td>
                          <div className='editDeleteIcons'>
                            <div className='editRow'>
                              <span title='Edit Form' className='editRowIcon' onClick={() => selectedFormClick(form.formId)}>
                                <FaPencilAlt />
                              </span>
                            </div>
                            <div className='deleteRow'>
                              <span title='Delete Form' className='deleteRowIcon' onClick={() => deleteForm(form.formId)}>
                                <FaTimes />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            <ReactPaginate
                previousLabel={'<<'}
                nextLabel={'>>'}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
            <DeleteConfirmationModal
                isOpen={isDeleteConfirmationOpen}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
                selectedForm={selectedForm}
            />
        </div>
    )
}

export default ViewReportsForms;