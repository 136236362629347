// EditCompanyModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import Loader from '../../../../Loader/Loader';
import axios from 'axios';
import './EditFreqVisitorsModal.css'
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState';
import { AtomFreqCategoryDataState } from '../../../../RecoilState/AtomFreqCategoryDataState';
import { useRecoilValue } from 'recoil';

const EditFreqVisitorsModal = ({ isOpen, onClose, freqVisitor }) => {
  const [editableField, setEditableField] = useState(null);
  const [editedFreqVisitors, setEditedFreqVisitors] = useState({ ...freqVisitor });
  const [message, setMessage] = useState("");
  const viewFreqCategories = useRecoilValue(AtomFreqCategoryDataState);
  const [selectedCategory, setSelectedCategory] = useState('');
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;


  const handleEditClick = (fieldName) => {
    setEditableField(fieldName);
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
  
    const sendData={
      id: freqVisitor.id,
      name: editedFreqVisitors.name,
      surname: editedFreqVisitors.surname,
      SA_ID: editedFreqVisitors.SA_ID,
      category: selectedCategory,
     }
     console.log(sendData)
     axios.post(`https://myworxit.co.za/api/${companyWebValue}/update/updateFreqVisitors.php`,sendData)
     .then((res)=> {
       if (res.data.success) {
         onClose();  // Close the modal only if the response is true
       } else {
         setMessage(res.data.message);
       }
     })
     .catch((res) => {
      console.error("Error updating site:", res);
      setMessage("An error occurred during the update");
      
     });
  }

    const handleUpdateClick = () => {
     // After updating, switch back to view mode
    setEditableField(null);

    setEditedFreqVisitors(prevState => ({
      ...prevState,
      category: selectedCategory
    }));
  };

  const handleInputChange = (e) => {
    // Update the editedSite state when input values change
    const { name, value } = e.target;
    setEditedFreqVisitors((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update editedSite when the site prop changes
    setEditedFreqVisitors({ ...freqVisitor });
    setSelectedCategory(freqVisitor.category); // Update selectedCategory when freqVisitor changes
  }, [freqVisitor]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <div className="modalHeader">
          <div className="header-title-cont"> 
            <h3 className='SitesZones-title'>Frequent Visitors Details</h3>
          </div>
            <div className='defaultBtnCont'>
              <span onClick={handleUpdateSubmit} className='defaultBtn'>Update</span>
            </div>
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Name:</span>
        </div>
          
          {editableField === 'name' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="name"
                value={editedFreqVisitors.name}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedFreqVisitors.name}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('name')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Surname:</span>
        </div>
          
          {editableField === 'surname' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="surname"
                value={editedFreqVisitors.surname}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedFreqVisitors.surname}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('surname')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>SA ID:</span>
        </div>
          
          {editableField === 'SA_ID' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="SA_ID"
                value={editedFreqVisitors.SA_ID}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedFreqVisitors.SA_ID}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('SA_ID')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Category:</span>
        </div>
          
          {editableField === 'category' ? (
            <div className='editable-field-col2'>
                <div className='editable-field-col2-col1'>
                  <select
                    id="editDeviceDropdown"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">-- Select --</option>
                    {viewFreqCategories.map((item) => (
                    <option key={item.id} value={item.category}>
                      {item.category}
                    </option>
                      ))}
                  </select>
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedFreqVisitors.category}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('category')}
                />
              </div>
             
            </div>
          )}
        </div>       
      </div>
    </div>
  );
};

export default EditFreqVisitorsModal;
