import { atom} from 'recoil';

export const AtomFreqCategoryDataState = atom({
  key: 'AtomFreqCategoryDataState',
  dangerouslyAllowMutability: true,
  default: [],
  // Add the following line to your code to clear the default state:
  initializeState: ({ set }) => {
    set(AtomFreqCategoryDataState, []);
  },
});
