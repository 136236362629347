// ViewUserZonesModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import Loader from '../../../../Loader/Loader';
import axios from 'axios';
import './ViewUserZonesModal.css';
import ReactPaginate from 'react-paginate';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import Checkbox from '@mui/material/Checkbox';
// ... (import statements)

const ViewUserZonesModal = ({ isOpen, onClose, site, user }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [viewSelectedZone, setViewSelectedZone] = useState([])
  //const [editedZone, setEditedZone] = useState({ ...site });
  const [editedZone, setEditedZone] = useState({...user});
  const [isLoading, setIsLoading] = useState(false);
  const [filteredZones, setFilteredZones] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);
 // const [checkedZones, setCheckedZones] = useState([...user.zone]); // Set initial state
 const [checkedZones, setCheckedZones] = useState([])

  const ZonesTable = async () => {
    try {
      const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewZones.php`);
      const newData = response.data.records;
      setViewSelectedZone(newData);
      setIsLoading(false);
     
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
    
useEffect(() => {
    ZonesTable(); // Initial call
    const intervalId = setInterval(ZonesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewSelectedZone]); // Empty dependency array to run this effect only once

   
  const viewSites = site.site; // Assuming 'site' is an object


  useEffect(() => {
    console.log('user.zone:', user.zone);
    setCheckedZones([...(user.zone || [])]);
  }, [user.zone]);

useEffect(() => {
  // ...
  const zones = viewSelectedZone.filter((zone) =>
    viewSites.includes(zone.site)
  );
  console.log('zones: ', zones)
  // Use the set function to update the state
  setFilteredZones(zones);
}, [viewSites, viewSelectedZone]);

  
  console.log('viewSites: ', viewSites)

  

  const handleCheckboxChange = (zone, isChecked) => {
    setCheckedZones((prevCheckedZones) => {
      if (isChecked) {
        // If the checkbox is checked, add the zoneName to the array only if it's not already present
        return [...new Set([...prevCheckedZones, zone])];
      } else {
        // If the checkbox is unchecked, remove the zoneName from the array
        return prevCheckedZones.filter((checkedZone) => checkedZone !== zone);
      }
    });
  
    // Update the editedZone state if needed
    setEditedZone((prevEditedZone) => {
      const existingZones = Array.isArray(JSON.parse(prevEditedZone.zone)) ? JSON.parse(prevEditedZone.zone) : [];
  
      if (isChecked) {
        // If the checkbox is checked, add the zoneName to the array
        const updatedZones = [...new Set([...existingZones, zone])];
        return { ...prevEditedZone, zone: JSON.stringify(updatedZones) };
      } else {
        // If the checkbox is unchecked, remove the zoneName from the array
        const updatedZones = existingZones.filter((existingZone) => existingZone !== zone);
        return { ...prevEditedZone, zone: JSON.stringify(updatedZones) };
      }
    });
  };
  
  
  
const handleUpdate=(e)=>{
    e.preventDefault();

    const updateZones = editedZone.zone ? JSON.parse(editedZone.zone).filter(zone => zone.trim() !== "") : [];
  console.log('cleanedZones: ', updateZones)
    // Concatenate the cleaned zones and checked zones
    // const updatedSites = [...new Set([...cleanedZones, ...checkedZones])];
    // console.log('updatedSites: ', updatedSites)

   const sendData={
      id: user.id,
      zone: updateZones

    }
    console.log(sendData)
    axios.post(`https://myworxit.co.za/api/updateUsersZones.php`,sendData)
    .then((res)=> {
      if (res.data.success) {
        onClose();  // Close the modal only if the response is true
      } 
    })
    .catch((res) => {
      console.error(res);
     
    });
  }
  // useEffect(() => {
  //   // Update editedSite when the site prop changes
  //   setEditedSite({ ...site });
  // }, [site]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <div className='modalHeader'>
         <div className='header-title-cont'>
            <h3 className='SitesZones-title'>Zones Details</h3>
          </div>
          <div className='defaultBtnCont'>
            <span className='defaultBtn' onClick={handleUpdate}>Save</span>
          </div>
          <div onClick={onClose} className='closeContAllModals' >
            <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Site:</span>
        </div>
          
          <label style={{color: "black"}}>{viewSites}</label>
        </div>
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Zone ID</th>
                    <th>Zone Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {filteredZones
            .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
            .map((viewZones) => (
              <tr key={viewZones.id}>
                <td>{viewZones.zoneId}</td>
                <td>{viewZones.zone}</td>
                <td>
                    <div className='checkBoxCont'>
                      <Checkbox
                        checked={
                          editedZone.zone
                            ? JSON.parse(editedZone.zone).includes(viewZones.zone)
                            : false
                        }
                        onChange={(e) => handleCheckboxChange(viewZones.zone, e.target.checked)}
                      />
                    </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>   
        <div className="rows-per-page">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={Math.ceil(filteredZones.length / usersPerPage)}
            onPageChange={({ selected }) => setPageNumber(selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
            </div>
      </div>
    </div>
  );
};

export default ViewUserZonesModal;
