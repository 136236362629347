// EditZonesModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import { useRecoilValue } from 'recoil';
import Loader from '../../../../Loader/Loader';
import axios from 'axios';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import CreateZonesModal from '../CreateZonesModal/CreateZonesModal';
import './EditZonesModal.css';
import ReactPaginate from 'react-paginate';
// ... (import statements)

const EditZonesModal = ({ isOpen, onClose, zone }) => {
  const [editableField, setEditableField] = useState(null);
  const [editedZones, setEditZones] = useState({ ...zone });
  const [message, setMessage] = useState("");
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;

  // const ZonesTable = async () => {
  //   try {
  //     const response = await axios.get(`https://myworxit.co.za/api/${updatedValue}/view/viewZones.php`);
  //     const newData = response.data.records;
  //     setViewSelectedZone(newData);
  //     setIsLoading(false);
      
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Error fetching data: ", error);
  //   }
  // };
    
// useEffect(() => {
//     ZonesTable(); // Initial call
//     const intervalId = setInterval(ZonesTable, 3000);
//     return () => clearInterval(intervalId);
//   }, [setViewSelectedZone]); // Empty dependency array to run this effect only once

  const handleEditClick = (fieldName) => {
    setEditableField(fieldName);
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
  
    const sendData={
      id: zone.id,
      zoneId: editedZones.zoneId,
      zone: editedZones.zone  
     }
     console.log(sendData)
    
    const apiUrl = `https://myworxit.co.za/api/${companyWebValue}/update/updateZone.php`;
     
    console.log('apiUrl: ', apiUrl)
     axios.post(apiUrl, sendData)
     .then((res)=> {
       if (res.data.success) {
         onClose();  // Close the modal only if the response is true
       } else {
         setMessage(res.data.message);
       }
       // setMessageSuc(res.data.messageSuc);
       // setMessageError("");
       // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
     })
     .catch((res) => {
      console.error("Error updating site:", res);
      setMessage("An error occurred during the update");
      
     });
  }

  const handleUpdateClick = () => {
     // After updating, switch back to view mode
    setEditableField(null);
  };

  const handleInputChange = (e) => {
    // Update the editedSite state when input values change
    const { name, value } = e.target;
    setEditZones((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update editedSite when the site prop changes
    setEditZones({ ...zone });
  }, [zone]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <div className="modalHeader">
          <div className='header-title-cont'>
            <h3 className='SitesZones-title'>Zone Details</h3>
          </div>
          <div className='defaultBtnCont'>
            <span className='defaultBtn' onClick={handleUpdateSubmit}>Save</span>
          </div>
          <div onClick={onClose} className='closeContAllModals' >
            <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
        </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Zone ID:</span>
        </div>
          
          {editableField === 'zoneId' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="zoneId"
                value={editedZones.zoneId}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedZones.zoneId}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('zoneId')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Zone Name:</span>
        </div>
          
          {editableField === 'zone' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="zone"
                value={editedZones.zone}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedZones.zone}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('zone')}
                />
              </div>
             
            </div>
          )}
        </div>
        
          
      </div>
    </div>
  );
};

export default EditZonesModal;
