// UserModal.js
import React, { useState } from 'react';
import './FormBuilderModal.css';
import CreateForm from './CreateForm';
import { AtomFormsDataState } from '../../../RecoilState/AtomFormsDataState';
import { AtomAccessControlDataState } from '../../../RecoilState/AtomAccessControlDataState';
import { useRecoilValue } from 'recoil';
import { FaTimes } from 'react-icons/fa';
import ViewReportsForms from './ViewReportsForms/ViewReportsForms';
import ViewAccessControlForms from './ViewAccessControlForms/ViewAccessControlForms';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const FormBuilderModal = ({ isOpen, onClose }) => {
  const [columns, setColumns] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showSelectedForm, setShowSelectedForm] = useState(false);
  const formsCreated = useRecoilValue(AtomFormsDataState);
  const accessFormsCreated = useRecoilValue(AtomAccessControlDataState);
  const [selectedForm, setSelectedForm] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Initialize the active tab index
  const [isAccessControlChecked, setIsAccessControlChecked] = useState(false)

  const handleTabSelect = index => {
    setActiveTabIndex(index); // Update the active tab index when a tab is clicked
    if (index === 1) {
      setIsAccessControlChecked(true); // Check the checkbox if "Access Control" tab is selected
    } else {
      setIsAccessControlChecked(false); // Uncheck the checkbox for other tabs
    }
  };

  // useEffect(() =>{
  //   console.log('formsCreated: -----------', formsCreated)
  //   console.log('accessFormsCreated: ---------', accessFormsCreated)
  // })

  const filteredData = formsCreated.filter(
    (item) => item.elementId === 'et' && item.fieldName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const selectedFormClick = (formId) => {
    setShowSelectedForm(true);

    const selectedForms = formsCreated.filter((item) => item.formId === formId);

    if (selectedForms.length > 0) {
      setSelectedForm(selectedForms);
    }
  };

  const filteredAccessData = accessFormsCreated.filter(
    (item) => item.elementId === 'et' && item.fieldName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const selectedAccessFormClick = (formId) => {
    setShowSelectedForm(true);

    const selectedForms = accessFormsCreated.filter((item) => item.formId === formId);

    if (selectedForms.length > 0) {
      setSelectedForm(selectedForms);
    }
  };

   const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(0); // Reset page number when searching
  };

  const createFormClick = () => {
    setShowCreateForm(true);
    setColumns([]);
  };

  const handleModalClose = () => {
    // Reset pagination and refetch data when modal is closed
    setPageNumber(0);
    onClose();
  }; 

  if (!isOpen) {
    return null;
  }

  return (
    <div className="FormBuilder-modal">
      <div className="FormBuilder-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="FormBuilder-title">Form Builder</h3>
          </div>
          <div className='formBuilderBtnCont'>
            <span className='formBuilderBtn' onClick={createFormClick}>Create Form</span>
          </div>
          <div onClick={handleModalClose} className='closeContAllModals'>
            <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
            </div>
        </div>
        
        
        <div>
          
          <div className='searchFormNameCont'>
            <div className='searchFormCont'>
              <label className='searchName' htmlFor="searchDropdown">Search Form Name:</label>
            </div>
            <div>
              <input
                type="text"
                placeholder="Search by form name"
                value={searchTerm}
                onChange={handleSearch}
                className="searchInput"
              />
            </div>
          </div>
          <div className="formsListTitleCont">
            <h4 className="formsListTitle">Saved Forms</h4>
          </div>
          <Tabs onSelect={handleTabSelect} selectedIndex={activeTabIndex}>
            <TabList>
              <Tab>Reports</Tab>
              <Tab>Access Control</Tab>
            </TabList>

            <TabPanel>
              <ViewReportsForms 
                selectedFormClick={selectedFormClick}
                filteredData={filteredData}
                selectedForm={selectedForm}/>
            </TabPanel>
            <TabPanel>
                <ViewAccessControlForms 
                selectedAccessFormClick={selectedAccessFormClick}
                filteredAccessData={filteredAccessData}
                selectedForm={selectedForm}/>
            </TabPanel>
          </Tabs>
         
          
        </div>
        
      </div>
      {showCreateForm && (
        <CreateForm
          columns={columns}
          setColumns={setColumns}
          isOpen={showCreateForm}
          onRequestClose={() => setShowCreateForm(false)}
          setShowCreateForm={setShowCreateForm}
          setIsAccessControlChecked={setIsAccessControlChecked}
          isAccessControlChecked={isAccessControlChecked}
        />
      )}
      {showSelectedForm && (
        <CreateForm
          columns={columns}
          setColumns={setColumns}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
          isOpen={showSelectedForm}
          setShowSelectedForm={setShowSelectedForm}
          showSelectedForm={showSelectedForm}
          onRequestClose={() => setShowSelectedForm(false)}
          activeTabIndex={activeTabIndex}
          isAccessControlChecked={isAccessControlChecked}
          setIsAccessControlChecked={setIsAccessControlChecked}
        />
      )}
      {/* <DeleteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        selectedForm={selectedForm}
      /> */}
    </div>
  );
};

export default FormBuilderModal;
