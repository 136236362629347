import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { AtomReportNamesState } from '../../../RecoilState/AtomReportNamesState';
import './MovementModal.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FaTimes } from 'react-icons/fa';
import { LiaTimesSolid } from "react-icons/lia";
import { CgPlayListAdd } from "react-icons/cg";
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';
import MovementLoader from './MovementLoader/MovementLoader';
import jsPDF from 'jspdf';
import worxitLogo from '../../../Media/worxitLogoLarge.png'
import { FaRegImage } from "react-icons/fa6";


//import 'rsuite/dist/rsuite.min.css';

const MovementModal = ({ isOpen, onClose }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;
  const [viewMovementEmoData, setViewMovementEmoData] = useState([]);
  const [viewMovementEmtData, setViewMovementEmtData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    try {
      // First axios.get call
      const responseEmo = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewMovementOutData.php`, {
        params: {
          uID: searchValue,
        },
      });
    
      const newDataEmo = responseEmo.data;
      console.log('newDataEmt: ', newDataEmo)

      setViewMovementEmoData(newDataEmo);
      // Second axios.get call
      const responseEmt = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/viewMovementToData.php`, {
        params: {
          uID: searchValue,
        },
      });
    
      const newDataEmt = responseEmt.data;
      console.log('newDataEmt: ', newDataEmt)
      setViewMovementEmtData(newDataEmt);
      // Update state
     // setIsLoading(false);
      setSearchClicked(true);
    } catch (error) {
      console.error("Error fetching data: ", error);
     // setIsLoading(false);
    }
  };
  
  useEffect(() =>{
      console.log('view Movement Emo Data: ', viewMovementEmoData)
  console.log('view Movement Emt Data: ', viewMovementEmtData)

  })

  
   const resetData = () => {

    setViewMovementEmoData([]);
    setViewMovementEmtData([]);
    setSearchValue('');
    setSearchClicked(false)
  };

  const handleCloseModal = () => {
    onClose();
    resetData();
  };

  if (!isOpen) {
    return null;
  }

  // console.log('dateRange: ', dateRange)
  // console.log('filteredReports: ', filteredReports)

  const filteredEmo = viewMovementEmoData.length > 0 
  ? viewMovementEmoData
    .filter((item) => item.fieldData.length > 0)
    .map((item) => item.fieldData)
  : [];

  const filterFieldEmoNameData =  viewMovementEmoData.length > 0 
  ? viewMovementEmoData
    .filter((item) => item.fieldData.length > 0)
  : [];

  const fieldEmoNameData = filterFieldEmoNameData.map((item, index) => (
    <div key={index} className="table-row-emo">
      <div className="table-cell" style={{ fontWeight: 'bold' }}>{item.fieldName}</div>
    </div>
  ))
  console.log('fieldEmoNameData: ', fieldEmoNameData)

  const filteredEmt = viewMovementEmtData.length > 0 
  ? viewMovementEmtData
    .filter((item) => item.fieldData.length > 0)
    .map((item) => item.fieldData)
  : [];

  const filterFieldEmtNameData = viewMovementEmtData.length > 0 
  ? viewMovementEmtData
    .filter((item) => item.fieldData.length > 0)
  : [];

  const fieldEmtNameData = filterFieldEmtNameData.map((item, index) => (
    <div key={index} className="table-row-emt">
      <div className="table-cell" style={{ fontWeight: 'bold' }}>{item.fieldName}</div>
    </div>
  ))
 
  const exportToPDF = () => {
    const pdf = new jsPDF();
    const reportDate = new Date().toLocaleString();
    pdf.setFontSize(8);
    //pdf.setFont('Lato-Regular', 'normal');
    pdf.setTextColor(50, 50, 50);
    pdf.text(`${reportDate}`, 165, 10);
    
    pdf.addImage(worxitLogo, "PNG", 15, 5, 50, 20);
      
    const fieldEmoNames = filterFieldEmoNameData.map((item, index) => {
      return item.fieldName;
    });

    pdf.setFontSize(12)
    pdf.text(`${fieldEmoNames}`, 15, 35);
    
    // Define the column headers
    const headers = ['uID','Quantity','Barcode', 'Description', ''];
  
    // Define the rows of the table
    const rowsEmo = [];
    filteredEmo.forEach((fieldDataArray) => {
      fieldDataArray.filter((item) => !!item).forEach((item) => {
        rowsEmo.push([
          item.uID,
          item.qtyNo,
          item.barcode,
          item.description,
          item.imageUri
        ]);
      });
    });
    // Define the width ratio for each column
    const columnWidths = [30, 30, 30, 30, 50];
  
    // Add the table to the PDF document
    pdf.autoTable({
      head: [headers],
      body: rowsEmo,
      startY: 45,
      margin: { top: 20 },
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] }
      },
    })
    

    const fieldEmtNames = filterFieldEmtNameData.map((item, index) => {
      return item.fieldName;
    });

    pdf.setFontSize(12)
    pdf.text(`${fieldEmtNames}`, 15, pdf.previousAutoTable.finalY + 15);

    const rowsEmt = [];
      filteredEmt.forEach((fieldDataArray) => {
        fieldDataArray.filter((item) => !!item).forEach((item) => {
          rowsEmt.push([
            item.uID,
            item.qtyNo,
            item.barcode,
            item.description,
            item.imageUri
          ]);
        });
      });


    pdf.autoTable({
      head: [headers],
      body: rowsEmt,
      startY: pdf.previousAutoTable.finalY + 35,
      margin: { top: 20 },
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] }
      }
    });
  
    // Save the PDF
    pdf.save('movement_data.pdf');
  };
  
  
  return (
    <div className="movement-modal">
      <div className="movement-modal-content">
         <div className='reportHeaderCont'>
          <div className='headerTitle'>
              <h3>Movement</h3>
            </div>
            <div onClick={handleCloseModal} className='closeContReportModal' >
            <span className='closeReportModalIcon'>
              <FaTimes />
            </span>
          </div>
          </div>
        <div className='searchMovementCont'>
       
          <div className='searchMovementColumn'>
          <div className='searchMovementAnythingCont'>
              <div className='searchMovementNameCont'>
                <label className='searchMovementName' htmlFor="searchDropdown">Movement Enquiry</label>
              </div>
              <div className='searchMovementAnything'>
                 <input
                    className='inputSearchMovementAnythingComponent'
                    type="text"
                    placeholder='Search for anything'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
              />
              </div>
              <div onClick={resetData} className='clearMovementSearchAnythingCont' >
                <span className='clearSearchAnythingIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div>
               <div className='searchMovementBtnCont'>
            <button className='searchBtn' onClick={handleSearch}>Search</button>
          </div>
            </div>
            
          </div>
         
        </div>

        <div className='movementTable' id='movementsTable'>
  {isLoading ? (
    <div>
      <MovementLoader />
    </div>
  ) : (
    <>      
     <div className='tableEmo'>
      {fieldEmoNameData}
      {/* {filteredEmt.length > 0 ? ( */}
        <div className='tableData'> 
          {filteredEmo.map((fieldDataArray, index) => (
            <div className='tableData' key={index}>
              {fieldDataArray.filter(item => !!item).map((item, innerIndex) => (
                <div key={innerIndex} className="table-row-emo">
                 <div className="table-cell-one">
                  <div className="table-cell-header">
                    uID
                  </div>
                  <div className="table-cell-body">
                    {item.uID}
                  </div>
                </div>
                <div className="table-cell-two">
                  <div className="table-cell-header">
                    Quantity
                  </div>
                  <div className="table-cell-body">
                    {item.qtyNo}
                  </div>
                </div>
                <div className="table-cell-three">
                  <div className="table-cell-header">
                    Barcode
                  </div>
                  <div className="table-cell-body">
                    {item.barcode}
                  </div>
                </div>
                <div className="table-cell-four">
                  <div className="table-cell-header">
                    Description
                  </div>
                  <div className="table-cell-body">
                    {item.description}
                  </div>
                </div>
                <div className="table-cell-five">
                  <div className="table-cell-header">
                    Image
                  </div>
                  <div className="table-cell-body">
                    <img src={item.imageUri} alt="ImageUri" />
                  </div>
                </div>
              </div>
              ))}
            </div>
          ))}
        </div>
      {/* ) : (
        <div>No results found.</div>
      )} */}


    </div>
    <div className='tableEmt'>
    {fieldEmtNameData}
     {/* {filteredEmt.length > 0 ? ( */}
      <div className='tableData'> 
        {filteredEmt.map((fieldDataArray, index) => (
          <div className='tableData' key={index}>
            {fieldDataArray.filter(item => !!item).map((item, innerIndex) => (
              <div key={innerIndex} className="table-row-emt">
                <div className="table-cell-one">
                  <div className="table-cell-header">
                    uID
                  </div>
                  <div className="table-cell-body">
                    {item.uID}
                  </div>
                </div>
                <div className="table-cell-two">
                  <div className="table-cell-header">
                    Quantity
                  </div>
                  <div className="table-cell-body">
                    {item.qtyNo}
                  </div>
                </div>
                <div className="table-cell-three">
                  <div className="table-cell-header">
                    Barcode
                  </div>
                  <div className="table-cell-body">
                    {item.barcode}
                  </div>
                </div>
                <div className="table-cell-four">
                  <div className="table-cell-header">
                    Description
                  </div>
                  <div className="table-cell-body">
                    {item.description}
                  </div>
                </div>
                <div className="table-cell-five">
                  <div className="table-cell-header">
                    Image
                  </div>
                  <div className="table-cell-body">
                    <img src={item.imageUri} alt="ImageUri" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
       
      </div>
    {/* ) : (
      <div>No results found.</div>
    )} */}

     
    </div>
    
    </>
    
  )}
      <div className='exportToPdfCont'>
          {searchClicked && (
            <button className='exportToPdfBtn' onClick={exportToPDF}>Export to PDF</button>
          )}
        </div>
      </div>
    
        
      </div>
    </div>
  );
};

export default MovementModal;
