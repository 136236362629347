import React, { useState } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import SuccessToast from '../../../SuccessToast/SuccessToast';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState';
import { AtomFreqCategoryDataState } from '../../../../RecoilState/AtomFreqCategoryDataState';
import { useRecoilState, useRecoilValue } from 'recoil';
import './AddVisitorModal.css';

const AddVisitorModal = ({ isOpen, onClose }) => {
    const [message, setMessage] = useState("");
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [categoryData, setCategoryData] = useRecoilState(AtomFreqCategoryDataState)
    const headerData = useRecoilValue(AtomHeaderDataState)
    const companyWebValue = headerData[0].companyWeb;
    const [selectedCategory, setSelectedCategory] = useState('')
    const [visitorData, setVisitorData] = useState({
      visitorPhoto: '',
      name: '',
      surname: '',
      SA_ID: '',
      category: ''
        // Add other fields as needed
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setVisitorData({
        ...visitorData,
        [name]: value,
      });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        const sendData = {
          visitorPhoto: visitorData.visitorPhoto,
          name: visitorData.name,
          surname: visitorData.surname,
          SA_ID: visitorData.SA_ID,
          category: selectedCategory,
          
        };
      
        console.log(sendData);
      
        axios.post(`https://myworxit.co.za/api/${companyWebValue}/create/createFreqVisitors.php`, sendData)
          .then((res) => {
            if (res.data.success) {
              setShowSuccessToast(true);
            } else {
              setMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message); // Assuming the error message is meaningful
          });
      };
      

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-company-modal">
      <div className="create-company-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
          <h3 className="create-company-title">Add Visitor</h3>
          </div>
          {/* {roleValue[0]?.role === 0 || roleValue[0]?.role === 1 ? ( */}
             <div className='defaultBtnCont'>
              <span onClick={handleSubmit} className='defaultBtn' type="submit">Add</span>
             </div>
            {/* ) : null
          } */}
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
          {/* <form onSubmit={handleSubmit}> */}
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Photo URI:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="visitorPhoto"
                value={visitorData.visitorPhoto}
                onChange={handleChange}
              />
              </div>   
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Name:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="name"
                value={visitorData.name}
                onChange={handleChange}
              />
              </div>   
                       
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Surname:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="surname"
                value={visitorData.surname}
                onChange={handleChange}
              />
              </div>            
            </div>         
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
                SA_ID:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="SA_ID"
                value={visitorData.SA_ID}
                onChange={handleChange}
              />
              </div>            
            </div>    
            <div className='createDeviceSelectedCoNoCont'>
              <div className='createDeviceCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateDeviceCompanyNoDropdown">Category:</label>
              </div>
              <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateDeviceCompanyNoDropdown"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {categoryData.map((item) => (
                    <option key={item.id} value={item.category}>
                      {item.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>     
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
             <SuccessToast 
                  message={message} 
                  isOpen={showSuccessToast} 
                  onToastClose={() => setShowSuccessToast(false)} 
                  onClose={() => onClose()} 
                />
      </div>
    </div>
  );
};

export default AddVisitorModal;
