// DeleteConfirmationModal.js
import React from 'react';
import './DeletefreqVisitorsModal.css'

const DeletefreqVisitorsModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="Delete-Site-modal">
      <div className="Delete-Site-modal-content">
        <h3 className='Delete-Site-modal-title'>Confirm Deletion</h3>
        <label className='confirmQues'>Are you sure you want to delete this site?</label>
        <div className='confirmCont'>
            <div className='yesCont'>
                <button className='yesBtn' onClick={onConfirm}>Yes</button>
            </div>
            <div className='noCont'>
                <button className='noBtn' onClick={onCancel}>No</button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DeletefreqVisitorsModal;
