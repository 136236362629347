import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import './EditUserDetailsModal.css';
import ViewUserSitesModal from '../ViewUserSitesModal/ViewUserSitesModal';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { AtomRolesState } from '../../../../RecoilState/AtomRolesState';
import { useRecoilValue } from 'recoil';

const EditUserDetailsModal = ({ isOpen, onClose, user }) => {
    const [editableField, setEditableField] = useState(null);
    const [editedUsers, setEditedUserDetails] = useState({ ...user });
    const [isViewUserSitesModalOpen, setisViewUserSitesModalOpen] = useState(false);
    const [viewCoNo, setViewCoNo] = useState([])
    const headerData = useRecoilValue(AtomHeaderDataState);
    const roleLevel = useRecoilValue(AtomRolesState);
    const userRoleValue = headerData[0].role;
    const roleValue = roleLevel.role[userRoleValue];
    const [selectedCoNo, setSelectedCoNo] = useState('');
    const selectedCoNoObject = viewCoNo.find(item => item.coNo === selectedCoNo);

    console.log('user: ', user)
     

    useEffect(() => {
      const fetchCompanyWeb = async () => {
        try {
          const response = await axios.get(`https://myworxit.co.za/api/fetchCompanyWeb.php`);
          const newData = response.data.records;
          setViewCoNo(newData);
          console.log('newData: ', newData);
      } catch (error) {
          console.error("Error fetching data: ", error);
      }
    };
    
      fetchCompanyWeb(); // Initial call
      const intervalId = setInterval(fetchCompanyWeb, 5000);
      return () => clearInterval(intervalId);
    }, [setViewCoNo]); // Empty dependency array to run this effect only once

    const handleUpdate=(e)=>{
      e.preventDefault();
  
  
     const sendData={
      id: user.id,
      username: editedUsers.username,
      name: editedUsers.name,
      userSurname: editedUsers.userSurname,
      identityNo: editedUsers.identityNo || 0,
      empNo: editedUsers.empNo,
      role: editedUsers.role,
      coNo: selectedCoNo || editedUsers.coNo,
      companyWeb: editedUsers.companyWeb,
      userContact: editedUsers.userContact || 0,
      userAddress: editedUsers.userAddress,
      userEmail: editedUsers.userEmail
    
      }
      console.log(sendData)
      axios.post(`https://myworxit.co.za/api/updateUserDetails.php`,sendData)
      .then((res)=> {
        if (res.data.success) {
          onClose();  // Close the modal only if the response is true
        } else {
          // setMessage(res.data.message);
        }
        // setMessageSuc(res.data.messageSuc);
        // setMessageError("");
        // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
      })
      .catch((res) => {
        console.error(res);
        // setMessage(res.data.message);
       
      });
    }

    const handleEditClick = (fieldName) => {
      setEditableField(fieldName);
    };

    const handleUpdateClick = () => {
      if (selectedCoNoObject) {
        // Update editedDevice state with selected coNo and its corresponding companyWeb
        setEditedUserDetails(prevDevice => ({
          ...prevDevice,
          coNo: selectedCoNo,
          companyWeb: selectedCoNoObject.companyWeb // Set companyWeb based on selected coNo
        }));
      } else {
        // Handle the case where the selected coNo object is not found
        console.error('Selected coNo object not found');
      }
      // After updating, switch back to view mode
     setEditableField(null);
   };

    const handleInputChange = (e) => {
      // Update the editedSite state when input values change
      const { name, value } = e.target;
      setEditedUserDetails((prevSite) => ({
        ...prevSite,
        [name]: value,
      }));
    };
  
    useEffect(() => {
      // Update editedSite when the site prop changes
      setEditedUserDetails({ ...user });
    }, [user]);
  

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-user-details-modal">
      <div className="create-user-details-modal-content">
        <div className="modalHeader">
          <div className="header-title-cont"> 
            <h3 className="create-user-details-title">User Details</h3>
          </div>
            <div className='defaultBtnCont'>
              <span onClick={handleUpdate} className='defaultBtn'>Update</span>
            </div>
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company No:</span>
        </div>
          
          {editableField === 'coNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
              <select
                  id="editDeviceDropdown"
                  value={selectedCoNo}
                  onChange={(e) => {
                    setSelectedCoNo(e.target.value);
                    }}
                >
                  <option value="">-- Select --</option>
                  {viewCoNo.map((item) => (
                    <option key={item.id} value={item.coNo}>
                      {item.coNo}
                    </option>
                  ))}
                </select>
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.coNo}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coNo')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company Web:</span>
        </div>
          
          {editableField === 'companyWeb' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="companyWeb"
                value={selectedCoNoObject.companyWeb}
                //onChange={handleInputChange}
              />
              </div>              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.companyWeb}</span>
              </div>
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Username:</span>
        </div>
          
          {editableField === 'username' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="username"
                value={editedUsers.username}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.username}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('username')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Role:</span>
        </div>
          
          {editableField === 'role' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="role"
                value={editedUsers.role}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.role}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('role')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Name:</span>
        </div>
          
          {editableField === 'name' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="name"
                value={editedUsers.name}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.name}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('name')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Surname:</span>
        </div>
          
          {editableField === 'userSurname' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userSurname"
                value={editedUsers.userSurname}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.userSurname}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('userSurname')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>identity No:</span>
        </div>
          
          {editableField === 'identityNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="identityNo"
                value={editedUsers.identityNo}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.identityNo}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('identityNo')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Employee No:</span>
        </div>
          
          {editableField === 'empNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="empNo"
                value={editedUsers.empNo}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.empNo}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('empNo')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Contact No:</span>
        </div>
          
          {editableField === 'userContact' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userContact"
                value={editedUsers.userContact}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.userContact}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('userContact')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Email:</span>
        </div>
          
          {editableField === 'userEmail' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userEmail"
                value={editedUsers.userEmail}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.userEmail}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('userEmail')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Address:</span>
        </div>
          
          {editableField === 'userAddress' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userAddress"
                value={editedUsers.userAddress}
                onChange={handleInputChange}
              />
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Save' className='editable-field-col2-col2'>
                    <MdSaveAlt
                      className="update-icon"
                      onClick={handleUpdateClick}
                    />
                  </div>
                ) : null 
              }
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedUsers.userAddress}</span>
              </div>
              {roleValue.iconEdit === '1' ? (
                  <div title='Edit' className='editable-field-col3-col2'>
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick('userAddress')}
                    />
                  </div>
                ) : null 
              }
            </div>
          )}
        </div>
        {roleValue.btnSite === '1' ? (
            <div className="editable-field">
              <div className='editable-field-col1'>
                <span className='defaultBtn' onClick={() => setisViewUserSitesModalOpen(true)}>Sites</span>
              </div>
            </div>
          ) : null 
        }
        {isViewUserSitesModalOpen && (
          <ViewUserSitesModal 
            isOpen={isViewUserSitesModalOpen} 
            onClose={() => setisViewUserSitesModalOpen(false)} 
            user={user}/>
        )}
          {/* <form onSubmit={handleSubmit}>
             <div className='createUserSubBtnCont'>
              <button className='createUserSubBtn' type="submit">Create User</button>
             </div>
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
          </form> */}
         
      </div>
    </div>
  );
};

export default EditUserDetailsModal;
