// import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
// import L from 'leaflet';
// import locationMarker from '../../../../Media/locationMarker.png'
import './RenderFieldData.css'

export const renderFieldData = (field) => {
    switch (field.elementId) {
      // case 'egps':
      //   return renderGPSField(field);
      case 'eurl':
      case 'eurls':
      case 'esign':
        return renderImageOrSignature(field);
      case 'eqr':
      case 'e2ds':
      case 'e2d':
        return renderBarcodeField(field);
      case 'evid':
        return renderVideoField(field);
      case 'esadl2d':
        return renderScanSADriversLicenseField(field);
      case 'e2dscandisk':
      case 'eqrscandisk':
        return renderScandiskField(field);
      case 'emo':
      case 'emo2d':
      case 'emt':
      case 'emt2d':
        return renderMovementField(field);
      case 'eim':
        return renderImageWithMediaField(field);
      default:
        return field.fieldData;
    }
  };
  
  const renderImageOrSignature = (field) => (
    field.fieldData ? (
      <img className='imageEurl' src={`data:image/png;base64,${field.fieldData}`} alt={field.fieldName} />
    ) : (
      <span>No {field.elementId === 'esign' ? 'signature' : 'image'} captured</span>
    )
  );
  
  const renderBarcodeField = (field) => (
    Array.isArray(field.fieldData) && field.fieldData.length > 0 ? (
        <table className="scanBarcodeTbl">
        <thead>
            <tr className='barcodeHeaderRow'>
                <th>Quantity</th>
                <th>Barcode</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
            {field.fieldData.map((item, itemIndex) => (
                <tr className='barcodeBodyRow' key={itemIndex}>
                    <td>{item.qtyNo}</td>
                    <td>{item.barcode}</td>
                    <td>{item.description}</td>
                </tr>
            ))}
        </tbody>
    </table>
    ) : (
      <div>
        <p>No Records Found</p>
      </div>
    )
  );
  
  const renderVideoField = (field) => (
    field.fieldData ? (
      field.fieldData
    ) : (
      <span>No video captured</span>
    )
  );

  const renderScanSADriversLicenseField = (field) => {
    const { results } = field.fieldData[0];
    
    if (results) {
      return (
        <div>
          <div>Initial: {results.Initial}</div>
          <div>Surname: {results.Surname}</div>
          <div>ID Number: {results.IDNo}</div>
          {results.url && (
            <img
              className='imageSADL'
              src={results.url}
              alt={`${field.fieldName} Image_`}
            />
          )}
        </div>
      );
    } else {
      return <span>No data available for 2D SADL Scan</span>;
    }
  };
  
  
  const renderScandiskField = (field) => (
    <div>
    {typeof field.fieldData === 'object' ? (
      <>
        <div>Vehicle Reg: {field.fieldData.vehicleReg}</div>
        <div>Vehicle Make: {field.fieldData.vehicleMake}</div>
        <div>Vehicle Colour: {field.fieldData.vehicleColour}</div>
        <div>Vehicle Model: {field.fieldData.vehicleModel}</div>
        <div>Vehicle Expires: {field.fieldData.vehicleExpires}</div>
        <div>Expired Status: {field.fieldData.expiredStatus}</div>
        <div>Access Status: {field.fieldData.accessStatus}</div>
      </>
    ) : (
      <div>{field.fieldData}</div>
    )}
  </div>
  );
  
  const renderMovementField = (field) => (
    Array.isArray(field.fieldData) && field.fieldData.some(item => Object.keys(item).length > 0) ? (
        field.fieldData.map((item, itemIndex) => (
          Object.keys(item).length > 0 && (
            <div className='emtAndEmoData' key={itemIndex}>
              {item.uID}, {item.qtyNo}, {item.barcode}, {item.description},{' '}
              <img className='imageEmtAndEmo' src={`data:image/png;base64,${item.imageUri}`} alt={field.fieldName} />
            </div>
          )
        ))
      ) : (
      <span>No image captured</span>
    )
  );
  
  const renderImageWithMediaField = (field) => (
    field.fieldData.map((media, index) => (              
        <div key={index} className='textWithMediaCont'>
            <div className='textCont'>
                {media.mediaText && <p>{media.mediaText}</p>}
            </div>
            <div className='mediaCont'>
                <div>
                    {media.imageLink && <img className='imageLink' src={media.imageLink} alt={`image_link`} style={{width: '100px'}}/>}
                </div>
                <div>
                    {media.videoLink && <video controls className='videoLink' style={{width: '100px'}}><source src={media.videoLink} type="video/mp4" /></video>}
                </div>
            </div>
        </div>
    ))
  );
  
  
  