import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './AccessControlDetailsModal.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AccessControlDetailsLoader from './AccessControlDetailsLoader/AccessControlDetailsLoader';
import { FaTimes } from 'react-icons/fa';

import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
import L from 'leaflet';
import locationMarker from '../../../Media/locationMarker.png'
import domtoimage from 'dom-to-image';
import ExportAccessControlToExcel from './ExportAccessControlToExcel/ExportReportToExcel';
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import { renderFieldData } from '../ReportsModal/RenderFieldData/RenderFieldData';
import { IoIosPaperPlane } from "react-icons/io";
import { FaFilePdf } from "react-icons/fa";
import { IoPrintSharp } from "react-icons/io5";
import { FaFileCsv } from "react-icons/fa";
// import PrintableComponent from './PrintReportDetails/PrintReportDetails';
import SendEmailModal from './SendEmailModal/SendEmailModal';

const AccessControlDetailsModal = ({ reportId, onClose }) => {
  const [reportDetails, setReportDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSendEmailModalopen, setIsEmailModalOpen] = useState(false);
  const mapRef = useRef(null);
  const headerData = useRecoilValue(AtomHeaderDataState)
  const companyWebValue = headerData[0].companyWeb;

  useEffect(() => {
    const fetchReportDetails = async () => {
     
      try {
        const response = await axios.get(`https://myworxit.co.za/api/${companyWebValue}/view/ViewAccessReportDetails.php?reportId=${reportId}`);
        const newData = response.data.records;
        console.log("Response data:", newData); // Log the response data
          
        setReportDetails(newData);
        setIsLoading(false); // Set loading to false when data is fetched
      
      } catch (error) {
        console.error("Error fetching report details: ", error);
        setIsLoading(false); // Set loading to false on error as well
      }
    };

    fetchReportDetails();
  }, [reportId, companyWebValue]);

   
  const filteredData = reportDetails.flatMap((report) => {
    let data = [];

    if (Array.isArray(report.reportData)) {
        // Handle the case where report.reportData is an array
        data = report.reportData;
    } else if (typeof report.reportData === 'object' && Array.isArray(report.reportData.data)) {
        // Handle the case where report.reportData is an object with a data property
        data = report.reportData.data;
    } else {
        // Handle other cases or log an error
        console.error("Invalid data structure:", report.reportData);
    }

    return data
        .filter((field) => field && field.elementId !== 'et' && field.elementId !== 'eemail')
        .map((field) => {
            let fieldValue = field.fieldData;

            // Convert boolean field value to "Yes" or "No"
            if (field.elementId === 'ebool') {
                fieldValue = field.fieldData ? 'Yes' : 'No';
            }

            return {
                id: field.id,
                elementId: field.elementId,
                fieldName: field.fieldName,
                fieldData: fieldValue,
            };
        });
});

  const customIcon = L.icon({
    iconUrl: locationMarker,
    iconSize: [14, 18], // Adjust the size as needed
  });

  const handleExportToPDFTest = async () => {
    const filteredReportId = reportDetails.map(({ reportId }) => reportId).join(', ');
    const filteredReportName = reportDetails.map(({ reportName }) => reportName).join(', ');

    // Assuming reportDetails[0].reportData is an array
    const filteredData = reportDetails[0].reportData.filter(item => item.elementId !== 'et');

    
    try {
        const response = await fetch(`https://myworxit.co.za/api/${companyWebValue}/sendReportViaEmail/sendReportViaEmail.php?downloadPDF=true`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reportId: filteredReportId,
                reportName: filteredReportName,
                reportData: {
                    data: filteredData
                }
            })
        });

        // Check if response is successful
        if (response.ok) {
            // Start downloading the PDF
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filteredReportName}.pdf`);

            // Append the link to the body and click it
            document.body.appendChild(link);
            link.click();

            // Cleanup: Remove the link and revoke the URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else {
            // Handle error
            console.error('Failed to download PDF');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  
    
  return (
    <div className="reportsDetails-modal">

      <div className="reportsDetails-modal-content">
        {/* <div className='reportTitleCont'>
          {reportDetails.map((report) => (
            <label className='searchName' key={report.id}>{report.reportName}</label>
          ))}
        </div> */}

        {isLoading ? (
          <div>
            <AccessControlDetailsLoader />
          </div>
        ) : (
          <>
        <div className='reportDetailsHeader'>
            <div className='headerActionIconsCont'>
              <div className='headerActionIcons'>
                <IoIosPaperPlane className='IoIosPaperPlane' onClick={() => setIsEmailModalOpen(true)}/>
              </div>
              <div className='headerActionIcons'>
                {/* <IoPrintSharp className='IoPrintSharp' onClick={handlePrint}/> */}
                {/* <PrintableComponent
                reportDetails={reportDetails}
                filteredData={filteredData}/> */}
              </div>
              <div className='headerActionIcons'>
                <FaFilePdf className='FaFilePdf' onClick={handleExportToPDFTest}/>
              </div>
              <div className='headerActionIcons'>
                <ExportAccessControlToExcel reportId={reportId}/>
              </div>
              <div className='headerActionIcons'>
                <FaFileCsv className='FaFileCsv'/>
              </div>
            </div>
          </div>
          <table className='reportsDetailsTitleTable'>
            <tbody className='reportsDetailsTableBody'>
              {reportDetails.map((report, index) => (
                <tr className='reportsDetailsRow' key={report.id}>
                  <td className='fieldNameRow'>Report Name</td>
                  <td className='fieldDataRow'> {report.reportName}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className='reportsDetailsTable' id='reportsTable'>
      <tbody className='reportsDetailsTableBody'>
        {filteredData.map((field, index) => (
          <tr className='reportsDetailsRow' key={field.id}>
            <td className='fieldNameRow'>
              {field.elementId === 'ets' ? (
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{field.fieldName}</span>
              ) : (
                field.fieldName
              )}
            </td>
            <td className='fieldDataRow'>
            {field.elementId === 'egps' ? (
                <>
                  <div>
                    {field.fieldData.latitude},{field.fieldData.longitude}
                  </div>
                  <div>
                    <MapContainer
                      center={[field.fieldData.latitude, field.fieldData.longitude]}
                      zoom={18}
                      style={{ height: '200px', width: '50%', position: 'sticky' }}
                      className='map'
                      zoomControl={false}
                      ref={mapRef}
                    >
                      <TileLayer
                        attribution='Map data &copy; OpenStreetMap contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      <Marker position={[field.fieldData.latitude, field.fieldData.longitude]} icon={customIcon}>
                        <Popup>
                          <div>
                            Latitude: {field.fieldData.latitude}
                            <br />
                            Longitude: {field.fieldData.longitude}
                            <br />
                          </div>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                </>
              ) :
              renderFieldData(field, mapRef)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
       
        
        {/* <div className='btnsContainer'>
          <div className='exportToPdfCont'>
            <button className='exportToPdfBtn' onClick={handleExportToPDF}>Export to PDF</button>
          </div>
          <ExportAccessControlToExcel reportId={reportId}/>
        </div>  */}
        </>
        )} 
        {/* <div className='deleteComponent'>
             <button className='closebtnContReportDetails' onClick={onClose}>X</button> 
            <span className='deleteIcon'></span>
        </div>  */}
        <div onClick={onClose} className='closeContReportDetails' >
          <span className='closeReportDetailsIcon'>
            <FaTimes />
          </span>
        </div>
      </div>
      {isSendEmailModalopen && (
        <SendEmailModal 
          isOpen={isSendEmailModalopen} 
          onClose={() => setIsEmailModalOpen(false)} 
          reportDetails={reportDetails}
        />
      )}
    </div>
  );
};

export default AccessControlDetailsModal;
