// UserModal.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import axios from 'axios';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { HiViewList } from "react-icons/hi";
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
import CreateUserModal from '../CreateUserModal/CreateUserModal';
import EditUserDetailsModal from '../EditUserDetailsModal/EditUserDetailsModal';
import ViewUserDetailsModal from '../ViewUserDetailsModal/ViewUserDetailsModal';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState';
import './UserModal.css';
import { useRecoilValue } from 'recoil';
import { AtomRolesState } from '../../../../RecoilState/AtomRolesState';
import DeleteUserModal from '../DeleteUserModal/DeleteUserModal';


const UserModal = ({ isOpen, onClose }) => {
  
  const [viewUsers, setViewUsers]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [isEditUserDetailsModalOpen, setEditUserDetailsModalOpen] = useState(false);
  const [isViewUserDetailsModalOpen, setViewUserDetailsModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [isSortingAsc, setIsSortingAsc] = useState(true);
 const [sortConfig, setSortConfig] = useState({ column: null, direction: 'ascending' });

  const [selectedUser, setSelectedUser] = useState(null);
  const headerData = useRecoilValue(AtomHeaderDataState);
  const userCompanyWeb = headerData[0].companyWeb;
  const roleLevel = useRecoilValue(AtomRolesState);
  const userRoleValue = headerData[0].role;
  const roleValue = roleLevel.role[userRoleValue];


const usersTable = useCallback(async () => {
  try {
    const response = await axios.get(`https://myworxit.co.za/api/viewUsers.php`);
    const newData = response.data.records;
    const filteredData = newData.filter((users) => users.companyWeb === userCompanyWeb);
     if (userCompanyWeb === 'worxit') {
      setViewUsers(prevDevices => newData); // Using functional form of setViewUsers
      // console.log('viewDevices: ', newData);
    } else {
      setViewUsers(prevDevices => filteredData); // Using functional form of setViewUsers
      // console.log('filteredData: ', filteredData);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error("Error fetching data: ", error);
  }
}, [userCompanyWeb, setViewUsers]); // Include setViewUsers in the dependency array

useEffect(() => {
  usersTable(); // Initial call
  const intervalId = setInterval(usersTable, 3000);
  return () => clearInterval(intervalId);
}, [usersTable]);
    
  const filteredSearch = viewUsers.filter((user) => {
    // Check if the user matches the search criteria
    const matchesSearch =
      searchColumn && searchKeyword && searchColumn !== ""
        ? user[searchColumn]?.toLowerCase().includes(searchKeyword.toLowerCase())
        : Object.values(user).some((value) =>
            value && value.toLowerCase().includes(searchKeyword.toLowerCase())
        );

    return matchesSearch 
});


  const editUsers = (user) => {
    setSelectedUser(user);
    setEditUserDetailsModalOpen(true);
  };

  const viewForms = (user) => {
    setSelectedUser(user);
    setViewUserDetailsModalOpen(true);
  };
  
    
  const handleModalClose = () => {
    // Reset pagination and refetch data when modal is closed
    setPageNumber(0);
    usersTable();
    onClose();
    setSortConfig('')
  };  
  //   const deleteUser = (id) =>{
  //     axios.delete(`https://myworxit.co.za/api/${crusaders}/delete/deleteUser.php`, {data: {id: id}})
  //     .then(()=>{
  //       usersTable();
  //     }).catch(()=>{
  //         alert("Error, Cannot Delete User!");
  //     })
  // }

  /*-----------PAGGINATION-------------*/
  const [pageNumber, setPageNumber] = useState(0);
  const reportsPerPage = 5; // Set the number of reports to display per page
  const pagesVisited = pageNumber * reportsPerPage;
  const pageCount = Math.ceil(filteredSearch.length / reportsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  /*-----------SORTING FILTER--------------*/


// Function to handle sorting
const handleSort = (column) => {
  // Toggle sorting order
  setIsSortingAsc((prev) => !prev);
  let direction = isSortingAsc ? 'ascending' : 'descending';
  setSortConfig({ column, direction });
};


// Function to sort data based on column and direction
const sortedData = useMemo(() => {
  let sortedUsers = [...filteredSearch];
  if (sortConfig.column !== null) {
    sortedUsers.sort((a, b) => {
     
      if (a[sortConfig.column] > b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      if (a[sortConfig.column] < b[sortConfig.column]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      return 0;
    });
  }
  return sortedUsers;
}, [filteredSearch, sortConfig]);

  const deleteForm = (id) => {
    setUserToDelete(id);
    setIsDeleteUserModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
        await axios.delete(`https://myworxit.co.za/api/deleteUser.php?id=${userToDelete}`);
        setIsDeleteUserModalOpen(false);
    
    } catch (error) {
      console.error("Error deleting form: ", error);
    }
  };

  const cancelDelete = () => {
    setUserToDelete(null);
    setIsDeleteUserModalOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="user-modal">
      <div className="user-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
          <h3 className='user-title'>User</h3>
        </div>
          {roleValue.userAddBtn === '1' ? (
            <div className='defaultBtnCont'> 
          <span className='defaultBtn' onClick={() => setCreateUserModalOpen(true)}>Add User</span>
          </div>
          ) : null
          
          }
        <div onClick={handleModalClose} className='closeContAllModals' >
          <span className='closeAllModalIcon'>
            <FaTimes />
          </span>
          </div>
        </div>
        
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="username">Username</option>
              <option value="role">Role</option>
              <option value="coNo">Company No</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
          <thead>
            <tr className={`filterSortColumns ${isSortingAsc ? 'open' : ''}`}>
              <th onClick={() => handleSort('username')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                   Username
                  {sortConfig.column === 'username' && (
                    <div className="arrow"></div>
                    )}
                </div>
              </th>
              <th onClick={() => handleSort('coNo')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Company No
                  {sortConfig.column === 'coNo' && (
                    <div className="arrow"></div>
                  )}
                </div>
              </th>
              <th onClick={() => handleSort('empNo')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Employee No
                  {sortConfig.column === 'empNo' && (
                    <div className="arrow"></div>
                  )}
                </div>
              </th>
              <th onClick={() => handleSort('role')} className="filterSortColumns-toggle">
                <div className='filterSortColumnsHeader'>
                  Role
                  {sortConfig.column === 'role' && (
                    <div className="arrow"></div>
                  )}
                </div>
              </th>
              <th>Status</th>
                {roleValue.iconEdit === '1' || roleValue.iconDelete === '1' || roleValue.viewUsers === '1' ? (
                    <th>Action</th>
                  ): null 
                }
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : sortedData.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  sortedData
                  .slice(pagesVisited, pagesVisited + reportsPerPage)
                    .map((viewUsers, index) => (
                      <tr key={index}>
                        <td>{viewUsers.username}</td>
                        <td>{viewUsers.coNo}</td>
                        <td>{viewUsers.empNo}</td>
                        <td>{viewUsers.role}</td>
                        <td>
                          <div
                            className={`statusCircle ${
                              viewUsers.status === '1' ? 'green' : 'red'
                            }`}
                          ></div>
                        </td>
                        {roleValue.iconEdit === '1' || roleValue.iconDelete === '1' || roleValue.viewUsers  === '1' ? (
                            <td>
                              <div className='editDeleteIcons'>
                              {roleValue.viewUsers === '1' ? (
                                    <div className='editRow'>
                                      <span title='Edit Site' className='editRowIcon' onClick={() => viewForms(viewUsers)}>
                                        <HiViewList />
                                      </span>
                                    </div>
                                  ) : null 
                                }
                                {roleValue.iconEdit === '1' ? (
                                    <div className='editRow'>
                                      <span title='Edit Site' className='editRowIcon' onClick={() => editUsers(viewUsers)}>
                                        <FaPencilAlt />
                                      </span>
                                    </div>
                                  ) : null 
                                }
                                {roleValue.iconDelete === '1' ? (
                                    <div className='deleteRow'>
                                      <span title='Delete Site' className='deleteRowIcon' onClick={() => deleteForm(viewUsers.id)}>
                                        <FaTimes />
                                      </span>
                                    </div>
                                  ) : null 
                                }
                              </div>
                            </td>
                          ) : null 
                        }
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
            </div>
            
          {isCreateUserModalOpen && (
          <CreateUserModal 
            isOpen={isCreateUserModalOpen} 
            onClose={() => setCreateUserModalOpen(false)} />
        )}

        {isEditUserDetailsModalOpen && (
          <EditUserDetailsModal 
            isOpen={isEditUserDetailsModalOpen} 
            onClose={() => setEditUserDetailsModalOpen(false)} 
            user={selectedUser}/>
        )}

        {isViewUserDetailsModalOpen && (
          <ViewUserDetailsModal 
            isOpen={isViewUserDetailsModalOpen} 
            onClose={() => setViewUserDetailsModalOpen(false)} 
            user={selectedUser}/>
        )}

      <DeleteUserModal
        isOpen={isDeleteUserModalOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      </div>
    </div>
  );
};

export default UserModal;
